<template>
	<div role='button' class="btn-close-lg svg-border" :class="prop?.class?prop.class:''"
			 @click="emit('onCloseClick')">
		<i class="fa-solid fa-xmark-large svg-shadow-sm" />
	</div>
</template>

<script setup>
const prop = defineProps(['class'])
const emit = defineEmits(['onCloseClick'])
</script>

<style scoped>
.btn-close-lg > svg {
	color: white;
	fill: white;
}
</style>
