<template>
  <div class="modal" tabindex="-1">
    <div class="modal-dialog modal-xs modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <img src="/aset/img/icon/logo-generated-navbar.webp" class="logo-icon rounded me-auto" />
        </div>
        <div class="modal-body px-2">
          <h5 :class="mdal.isSmallTextAlrt?'text-start px-3':'text-center'" v-html="mdal.getAlrtMsg"></h5>
        </div>
        <div class="modal-footer d-flex">
          <button
            type="button"
            class="btn btn-primary btn-confirm shadow-md"
            @click="mdal.onAlrtBtnClck()"
            v-html="
                mdal.getAlrtBtnNm
                  ? mdal.getAlrtBtnNm
                  : '<i class=\'fa-solid fa-check me-2\'></i>OK'
              "
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { mdal_stor } from '@/stor/mdal_stor'
const mdal = mdal_stor()
</script>

<style scoped>
h5 {
  line-height: 32px;
}

.logo-icon {
  height: 39px;
}

.modal-dialog {
  width: 420px;
  max-width: calc(100% - 6rem);
  min-height: fit-content;
  max-height: calc(100% - 1rem);
}

.modal-body {
  padding: 0.8rem 0 0.8rem;
  display: grid;
  vertical-align: middle;
}

.modal-body h5 {
  margin: auto;
  height: fit-content;
}

.modal-footer {
  padding-top: 0;
}
.modal-footer > .btn.btn-confirm {
  width: 66%;
  min-width: 100px;
}

h5.text-start {
  line-height: 1.5;
  font-weight: 500;
}
</style>
