<template>
  <div class="no-img-container" :class="prop.theme?prop.theme:''">
    <div class="no-img-wrapper">
      <div class="no-img-inner">
        <div class="img-box">
          <img class="alert-logo" src="@/assets/img/icon/img_nodata.svg" />
        </div>
        <div class="lodn-text text-center">There are no data</div>
      </div>
    </div>
  </div>
</template>

<script setup>
const prop = defineProps(['theme'])
</script>

<style scoped>
.alert-logo {
  content: url('@/assets/img/icon/img_nodata.svg');
  opacity: 0.7;
}

.no-img-container.white .alert-logo {
  content: url('@/assets/img/icon/img_nodata-w.svg');
}

.lodn-text {
  color: #999;
  font-weight: 500;
  font-size: 1rem;
}

.no-img-container.white .lodn-text {
  color: white;
  opacity: 0.6;
  font-weight: normal;
}
</style>
