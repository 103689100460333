<template>
	<div class="crtr-row" v-if="model.crtrInfo" :class="getClass()">

    <!-- Creator text -->
    <div v-if="model.showText" class="text txt-crtr">
      <div>
        Creator
      </div>
    </div>
    <!-- 레벨 아이콘 심플 -->
    <div class="levl-icon-wrap">
      <span class="levl-img-smal sm" :class="'levl-' + model.crtrInfo.crtrGrad"
            :style="'background-position-y: ' + model.icoPosY + 'px;'" />
    </div>
    <!-- 사진 -->
		<span class="prof-img-smal"
				 :style="`background-image: url('${model.crtrInfo.thumUrl ? model.crtrInfo.thumUrl : model.crtrInfo.phtoUrl ? model.crtrInfo.phtoUrl : emtyImgUrl }');`">
    </span>
    <!-- 이름 -->
    <div class="crtr-nm text ellipsis" @click="onCrtrClck" :class="(!prop.noClck)?'pe-all':''">
      <div>
        {{ model.crtrInfo.crtrNm ? model.crtrInfo.crtrNm : model.crtrInfo.dispName ? model.crtrInfo.dispName : "-" }}
      </div>
    </div>
    <span
      v-if="model.crtrInfo.natnCtry"
      class="flag float-none"
      :class="'flag-' + model.crtrInfo.natnCtry.toLowerCase()"
      :title="cnst.getCtryName(model.crtrInfo.natnCtry)">
    </span>
	</div>
</template>

<script setup>
import { onMounted, reactive, watch } from 'vue'
import emtyImgUrl from '@/assets/img/icon/ic-no-prof-img.webp'
import { cnst_stor } from '@/stor/cnst_stor'
import { user_stor } from '@/stor/user_stor'
import { parseJwt } from '@/util/auth_func'
import { BrwsStrgKey } from '@/util/comn_cnst'

const DEFL_HEGH = 32
const cnst = cnst_stor()
const user = user_stor()
const emit = defineEmits(['onClck'])
const prop = defineProps(['data', 'hide', 'algn', 'noClck', 'type', 'class'])
const model = reactive({
	src: emtyImgUrl,
  showText: false,
  crtrId: null,
  crtrInfo: null,
  icoPosY: -2,
	wdth: 32,
	hegt: 32,
  fromJwt: false
})

// crtrId, crtrNm, crtrGrad, natnCtry, phtoUrl
watch(() => prop.data,
	(param) => {
  model.crtrInfo = param
  console.log('data2', JSON.stringify(model.crtrInfo))
  model.crtrId = model.crtrInfo.crtrId ? model.crtrInfo.crtrId : model.crtrInfo.userId ? model.crtrInfo.userId : ''
  if (model.crtrInfo.crtrGrad) {
    const levl = parseInt(model.crtrInfo.crtrGrad.substring(4), 10)
    model.icoPosY = -1 * (levl - 1) * DEFL_HEGH - 2
  }
})

onMounted(() => {
  if (prop.type==null) {
    model.crtrInfo = prop.data
    model.showText = (prop.showText && prop.showText.includes('prfx'))
    model.crtrId = model.crtrInfo.crtrId ? model.crtrInfo.crtrId : model.crtrInfo.userId ? model.crtrInfo.userId : ''
  } else {
    let jwt = parseJwt(sessionStorage.getItem(BrwsStrgKey.FO_JWT))
    const crtrInfo = {
      thumUrl: jwt['thumUrl'],
      dispName: jwt['full_name']
    }
    if (jwt['ctry']) {
      crtrInfo.natnCtry = jwt['ctry']
    }
    if (jwt['crtrGrad']) {
      crtrInfo.crtrGrad = jwt['crtrGrad']
      model.showText = true
    }
    model.crtrInfo = crtrInfo
    model.fromJwt = true
  }

  if (model.crtrInfo.crtrGrad) {
    const levl = parseInt(model.crtrInfo.crtrGrad.substring(4), 10)
    model.icoPosY = -1 * (levl - 1) * DEFL_HEGH - 2
  }

})

function getClass() {
  let ret = (prop.algn && prop.algn==='end')?'justify-content-end text-primary fw-bold':''
  if (prop.type && prop.type==='jwt') {
    ret += ' from-jwt'
  }
  return ret
}

function onCrtrClck() {
  if (prop.noClck && prop.noClck==='true') {
    return
  }
  if (model.crtrId) {
    user.showCrtr(model.crtrId)
  }
}
</script>

<style scoped>
.text-primary {
  color: #0087a3 !important;
}
.txt-crtr {
  margin-right: 4px;
}
.crtr-row {
  display: flex;
  height: 26px;
  padding: 0;
  font-size: 0.95rem;
  color: #595959;
}
.crtr-row > * {
  line-height: 27px;
}
.crtr-nm {
  margin-left: 6px;
  font-weight: 500;
}
.crtr-nm > * {
  font-weight: 500;
}

.text {
  min-width: 26px;
  font-weight: 400;
}

.text > * {
  line-height: 1;
  vertical-align: bottom;
  letter-spacing: -0.5px;
}

.levl-img-smal {
  zoom: 1.37;
  position: absolute;
  width: 32px;
  height: 32px;
  left: -5px;
  top: -5px;
  background-position: center;
  background-size: cover;
  background-image: url("@/assets/img/icon/ic-rome-no-brdr-shdw.webp");
  background-position-x: 0;
  background-position-y: 0;
}
.fs-3 .levl-img-smal {
  top: -4px;
}
.fs-3 .text {
  font-size: 20px;
}
.fs-3 .text > * {
  padding-top: 5px;
  margin-top: 0;
  color: #686868;
  height: 26px;
}

.fs-4 .text {
  font-size: 1.3rem;
}
.fs-4 .text > * {
  padding-top: 5px;
  margin-top: 0;
  color: #686868;
  height: 26px;
}

.fw-bold .text {
  font-size: 1rem;
}

.prof-img-smal {
  min-width: 26px;
  width: 26px;
  height: 26px;
	border-radius: 5px;
	background-size: cover;
  display: inline-block;
  vertical-align: top;
	background-position: center;
	border: 1px solid #444;
}

.levl-icon-wrap {
  min-width: 26px !important;
  position: relative;
  width: 26px;
  height: 26px;
  margin: 3px 0 0 0;
}

.flag {
  margin-top: 4px;
  width: 26px;
  height: 18px;
  margin-left: 8px;
}

.pe-all {
  cursor: pointer;
}

.from-jwt .prof-img-smal {
  cursor: pointer;
}

.crtr-row.text-navy-bold .text {
  color: #0D47A1 !important;
}

@media (max-width: 991px) {
  .from-jwt {
    justify-content: end;
    padding-right: 1rem;
  }
}

@media (min-width: 992px) {
  .from-jwt {
    height: 36px;
    width: 36px;
  }
  .from-jwt .prof-img-smal {
    width: 100%;
    height: 100%;
  }
  .from-jwt .text, .from-jwt .levl-icon-wrap, .from-jwt .flag, .from-jwt .crtr-nm {
    display: none;
  }
}
</style>
