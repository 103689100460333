<template>
  <div class="container-fluid ctnt-wrap d-flex">

    <!-- left image detail pane -->
    <div class="col-left-img-detail-panel flex-grow-1">

      <!-- image content wrapper (full screen area) -->
      <div id="img-ctnt-wrap" class="img-ctnt-wrap position-relative">

        <zoomer-with-splide
          id="zoom-wrap"
          ref="zoomWrap"
          v-if="model.slctIdx > -1"
          :imgList="model.imgList"
          :grup="model.grup"
          :selectedIndex="model.slctIdx"
          @onImgLoad="onImgLoad"
          @currItemId="currItemId"
          @setFullScrn="setFullScrn"
        />

        <!-- key nav helper button group -->
        <div class="kybd-wrap"></div>
        <!-- key nav helper button group -->

        <spnr-no-block v-if="model.loading" theme="white" class="absolute" />

      </div>
      <!-- image content wrapper (full screen area) -->
    </div>
    <!-- Left col : image display column -->

    <!-- Right col : image description column -->
    <div class="col-right-img-info-panel pc-scrollbar" :class="prop.mode?'full':''"
         v-if="model.grup">
      <div class="scroll-inner">
        <div class="w-100 text-start left-cornered d-none">
          <hr class="hr-handle w-35 d-block d-lg-none">
          <div class="art-title">{{ model.grup.titl }}</div>
        </div>
        <div class="table-wrap table-responsive">
          <table class="table table-responsive">
            <colgroup>
              <col style="width: 170px;max-width: 50%;" />
              <col style="width: auto;min-width: 50%;" />
            </colgroup>
            <tbody>
            <tr class="border-0">
              <td colspan="2" class="col-crtr p-0 pe-2 bg-f3f3f3 border-0">
                <div class="row-rels d-flex">
                  <div class="fw-bold text">
                    {{ model.grup.rgstStep === ArtRgstStep.REQUESTED ? 'Submitted at' : 'Released' }}
                  </div>
                  <div class="ms-2 text">
                    {{ model.grup.rgstStep === ArtRgstStep.REQUESTED ? isoDateToShortYear4(model.grup.sbmtDttm) : isoDateToShortYear4(model.grup.aprvDttm)
                    }}
                  </div>
                  <div class="icon">
                    <i v-if="model.grup.rgstStep===ArtRgstStep.REQUESTED"
                       class="fa-regular fa-hand-wave ms-2 text-primary"></i>
                    <i v-if="model.grup.rgstStep!==ArtRgstStep.REQUESTED"
                       class="fa-solid fa-plane-departure ms-2 text-primary"></i>
                  </div>
                </div>

                <crtr-info-full-horz v-if="model.crtrInfo" :data="model.crtrInfo" class="row-crtr-info" algn="end" />

              </td>
            </tr>

            <tr class="bb-solid bt-solid">
              <th colspan="2">
                <div class="th-innr">
                  <div class="th-innr-left">
                    <span class="icon">
                      <i class="fa-regular fa-message-smile"></i>
                    </span>
                    <span class="text">
                    Creator's Message
                  </span>
                  </div>
                  <div class="th-innr-right with-btn">
                    <a
                      @click="showRprtModal"
                      class="btn btn-sm btn-white btn-outline-light-gray d-flex btn-rept float-right w-33"
                    >
                      <span>
                        <i class="fa-solid fa-flag"></i>
                      </span>
                      <span class="btn-txt me-2 fw-medium">
                        Report
                      </span>
                    </a>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <td colspan="2"
                  class="td-crtr-msg text-start">
                <h2 class="description">{{ model.grup.crtrMesg }}</h2>
              </td>
            </tr>

            <!-- generated with & media quality -->
            <tr class="bb-solid bt-solid">
              <th colspan="2">
                <div class="th-innr">
                  <div class="th-innr-left">
                    <span class="icon">
                      <i class="fa-regular fa-screwdriver-wrench"></i>
                    </span>
                    <span class="text">
                      Generated with
                    </span>
                  </div>
                  <div class="th-innr-right font-lg-wrap">
                    <span class="icon gen-tool">
                      <img
                        v-if="model.grup.prvdCd"
                        :src="'/aset/img/brand/' + model.grup.prvdCd + (model.grup.prvdCd==='FXVR'?'.svg':'.webp')"
                      />
                    </span>
                    <span class="text">
                    {{
                        model.grup.prvdCd
                          ? cnst.getCodeName('GNTL', model.grup.prvdCd)
                          : 'Not input yet'
                      }}
                    ({{ model.grup.modlNm ? model.grup.modlNm : cnst.getCodeName(model.grup.prvdCd, model.grup.modlCd)
                      }})
                    </span>
                  </div>
                </div>
              </th>
            </tr>

            <tr>
              <th>
                <div class="th-innr justify-content-between">
                  <div class="th-innr-left">
                    <span class="icon">
                      <i class="fa-duotone fa-solid fa-turn-down-right"></i>
                    </span>
                    <span class="text ellipsis fw-medium">
                      Image Quality (AVG)
                    </span>
                  </div>
                </div>
              </th>
              <td class="px-1 text-center">
                <div class="th-innr-right font-lg-wrap">
                  <span class="badge rounded-pill m-0 fs-8 imgs-qlty text" v-if="model.grup.avrgFcus">
                    {{ Math.round(model.grup.avrgFcus) }}% focus / {{ pxNum2Str(model.grup.avrgPixl)
                    }} / {{ byteNum2StrShrt(model.grup.avrgByts) }}
                  </span>
                </div>
              </td>
            </tr>

            <tr class="bb-solid">
              <th>
                <div class="th-innr-left">
                  <div class="icon">
                    <i class="fa-duotone fa-solid fa-turn-down-right"></i>
                  </div>
                  <div class="text">Additional Upscaler</div>
                </div>
              </th>
              <td class="text-end font-lg-wrap">
                <div class="text">
                {{
                  model.grup.upscCd
                    ? cnst.getCodeName('MJUP', model.grup.upscCd)
                    : model.grup.upscNm
                      ? model.grup.upscNm
                      : 'Not used'
                }}
                </div>
              </td>
            </tr>
            <!-- generated with & media quality -->

            <!-- content view grade -->
            <tr class="bb-solid">
              <th colspan="2">
                <div class="th-innr">
                  <div class="th-innr-left">
                    <span class="icon">
                      <img src="/aset/img/icon/icon-content-ratings.webp" class="ic-esrb" />
                    </span>
                    <span class="text">
                      Content Ratings
                    </span>
                  </div>
                  <div class="th-innr-right font-lg-wrap">
                    <template v-if="model.grup.ctntGradGrupFinl === ContentGrade.EVERYONE">
                      <span class="icon py-0">
                        <img src="@/assets/img/icon/ic-prop-cap.svg" />
                      </span>
                      <span class="text">
                        Everyone
                      </span>
                    </template>
                    <template v-if="model.grup.ctntGradGrupFinl === ContentGrade.TEEN">
                      <span class="icon py-0">
                        <img src="@/assets/img/icon/ic-MAAG0001.webp" />
                      </span>
                      <span class="text">
                        Teen
                      </span>
                    </template>
                    <template v-if="model.grup.ctntGradGrupFinl === ContentGrade.ADULT">
                      <span class="icon py-0">
                        <img src="@/assets/img/icon/ic-MAAG0002.webp" />
                      </span>
                      <span class="text">
                        Adult
                      </span>
                    </template>
                  </div>
                </div>
              </th>
            </tr>
            <!-- content view grade -->

            <tr>
              <th class="ellipsis">
                <div class="th-innr-left">
                  <div class="icon">
                    <i class="fa-duotone fa-solid fa-turn-down-right"></i>
                  </div>
                  <div class="text">Celebrities, Real People</div>
                </div>
              </th>
              <td class="font-lg-wrap">
                <div class="text">
                {{ (model.grup.celbCtntGrupCrtr) ? '<i class="fa-solid fa-circle-check"></i>' : '-' }}
                </div>
              </td>
            </tr>
            <tr class="bb-solid">
              <th>
                <div class="d-flex justify-content-between">
                  <div class="th-innr-left">
                    <div class="icon">
                      <i class="fa-duotone fa-solid fa-turn-down-right"></i>
                    </div>
                    <div class="text">Licensed Characters</div>
                  </div>
                </div>
              </th>
              <td class="font-lg-wrap">
                <div class="text">
                {{ model.grup.lcsdCtntGrupCrtr ? '<i class="fa-solid fa-circle-check"></i>' : '-' }}
                </div>
              </td>
            </tr>

            <!-- pricing -->
            <tr class="bb-solid"
                :class="(model.grup.celbCtntGrupCrtr===true || model.grup.lcsdCtntGrupCrtr===true) ? 'bt-solid' : ''">
              <th colspan="2">
                <div class="th-innr">
                  <div class="th-innr-left">
                    <span class="icon">
                      <i class="fa-regular fa-money-check-dollar"></i>
                    </span>
                    <span class="text">
                      Pricing
                    </span>
                  </div>
                  <div class="th-innr-right with-btn fw-normal">
                    USD
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th>
                <div class="d-flex justify-content-between">
                  <div class="th-innr-left">
                    <div class="icon">
                      <i class="fa-duotone fa-solid fa-turn-down-right"></i>
                    </div>
                    <div class="text">Prompt</div>
                  </div>
                </div>
              </th>
              <td class="font-lg-wrap">
                <div class="th-innr-right justify-content-between">
                  <span class="bdge-pmpt badge rounded-pill m-0 me-1 d-flex">
                    <span v-if="model.grup.pmptImgCnt > 0" class="d-flex">
                      <span>
                        <i class="fa-regular fa-image"></i>
                      </span>
                      <span class="mx-1"> + </span>
                    </span>
                    <span>{{ model.grup.pmptLeng }} letters</span>
                  </span>
                  <span class="text">
                    {{ model.grup.pmptPricAmt ? currFormat.format(model.grup.pmptPricAmt / 100) : '-' }}
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <th>
                <div class="d-flex justify-content-between">
                  <div class="th-innr-left">
                    <div class="icon">
                      <i class="fa-duotone fa-solid fa-turn-down-right"></i>
                    </div>
                    <div class="text">Generated Image</div>
                  </div>
                </div>
              </th>
              <td class="font-lg-wrap">
                <div class="th-innr-right justify-content-between">
                  <span class="badge rounded-pill img-cnt m-0 me-1">
                    {{ model.grup.mdiaCnt }} pcs/ea
                  </span>
                  <span class="text">
                    {{ model.grup.imgPricAmt ? currFormat.format(model.grup.imgPricAmt / 100) + '' : 'Free' }}
                  </span>
                </div>
              </td>
            </tr>
            <!-- pricing -->

            <tr class="bb-solid" role="button" @click="toglKywdShow">
              <th class="kywd-btn-container" colspan="2">
                <div class="th-innr">
                  <div class="th-innr-left">
                    <span class="icon">
                      <i class="fa-regular fa-tags"></i>
                    </span>
                    <span class="text">
                      Keywords
                    </span>
                  </div>
                  <div class="th-innr-right with-btn font-lg-wrap">
                    <span class="badge rounded-pill bdge-kywd-cnt text">
                      {{ model.grup.kywdGrupFinlPblc ? model.grup.kywdGrupFinlPblc.length : 0 }}
                    </span>
                    <btn-fold @btnFoldChanged="onBtnFoldChng" :opened="model.kywdOpen"
                              @click="toglKywdShow" />
                  </div>
                </div>
              </th>
            </tr>

            <tr v-show="model.kywdOpen" class="">
              <td colspan="2" class="td-kywd-wrap">
                <div
                  class="w-100 kywd-wrap"
                  v-if="model.grup.kywdGrupFinlPblc && model.grup.kywdGrupFinlPblc.length > 0"
                >
                  <span
                    class="badge emboss bg-white bdge-kywd shadow-sm"
                    v-for="(item, idx) in model.grup.kywdGrupFinlPblc"
                    :key="idx"
                  >
                    {{ item }}
                  </span>
                </div>
                <div
                  class="w-100 kywd-wrap"
                  v-if="!model.grup.kywdGrupFinlPblc || model.grup.kywdGrupFinlPblc.length < 1"
                >
                  No Keywords
                </div>
              </td>
            </tr>
            <tr v-if="model.grup && model.grup.purchased" class="bt-solid">
              <td colspan="2" class="bg-light-gray">
                <!-- download button -->
                <div class="btn-dnld mx-4 my-3 position-relative">
                  <a
                    @click="showDnldNoti(model.grup.grupId)"
                    class="btn btn-lg btn-dnld btn-flex btn-outline btn-primary shadow-md">
                    <i class="fa-regular fa-folder-arrow-down ms-1 me-2"></i>Download</a>
                  <div class="note-pcse-item" v-if="model.grup && model.grup.purchased">Purchased</div>
                </div>
                <!-- download button -->
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- button area : detail page -->
      <abs-bttm-btns-area
        :btnUse="['maxm', 'quot', 'shre', 'like', 'pcse']"
        :liked="model.grup && model.grup.liked"
        hideCnclBtn="true"
        @onFullScrnClck="setFullScrn(true)"
        @onLike="onLike"
        @onShre="onShre"
        @onQuot="onQuot"
        @onPcseBtnClck="onPcseBtnClck"
      />
      <!-- button area : detail page -->
      <!-- Right col : image description column -->

    </div>
  </div>

</template>

<script setup>
import ZoomerWithSplide from '@/view/cpnt/ZoomerWithSplide.vue'
import { onMounted, reactive, ref } from 'vue'
import { user_stor } from '@/stor/user_stor'
import {
  byteNum2StrShrt,
  isoDateToShortYear4, pxNum2Str
} from '@/util/comn_util'
import BtnFold from '@/view/btns/BtnFold.vue'
import { API_ROOT, ArtRgstStep, BrwsStrgKey, ContentGrade, ResCd } from '@/util/comn_cnst'
import { mdal_stor } from '@/stor/mdal_stor'
import { cnst_stor } from '@/stor/cnst_stor'
import SpnrNoBlock from '@/view/pgrsAlrt/SpnrNoBlock.vue'
import CrtrInfoFullHorz from '@/view/cpnt/CrtrInfoFullHorz.vue'
import { i18n } from '@/lcal/i18n'
import axis_cstm from '@/util/axis_cstm'
import FileDownload from 'js-file-download'
import AbsBttmBtnsArea from '@/view/btns/AbsBttmBtnsArea.vue'

const { t } = i18n.global
const zoomWrap = ref()
const cnst = cnst_stor()
const mdal = mdal_stor()
const prop = defineProps(['data', 'mode'])
const emit = defineEmits(['currItemId', 'btnFoldChanged', 'showRprtMdal', 'onQuot', 'onPcseClck'])
const user = user_stor()
const model = reactive({
  loading: false,
  grup: null,
  imgList: [],
  pricList: [],
  pricData: {},
  slctIdx: -1,
  slctCrcy: null,
  crcyList: [],
  kywdOpen: true,
  url: null,
  currUrl: window.location.href,
  tokn: sessionStorage.getItem(BrwsStrgKey.FO_JWT)
})
let currFormat

onMounted(() => {
  model.url = location.href.split('/view')[0]
  model.slctIdx = prop.data.index
  model.grup = prop.data.grup
  model.grup.kywdGrupFinlPblc = model.grup.kywdGrupFinlPblc ? model.grup.kywdGrupFinlPblc.sort() : []
  model.crtrInfo = {
    crtrId: model.grup.crtrId,
    crtrNm: model.grup.crtrNm,
    crtrGrad: model.grup.crtrGrad,
    natnCtry: model.grup.natnCtry,
    thumUrl: model.grup.thumUrl
  }
  model.imgList = prop.data.imgList
  model.pricList = prop.data.pricList
  model.slctCrcy = 'USD'
  currFormat = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: model.slctCrcy
  })
})

function getThum600Url() {
  return zoomWrap.value.getThum600Url()
}

function onImgLoad(val) {
  model.loading = val
}

function showRprtModal() {
  if (!user.isSignedIn) {
    mdal.confirm(
      'Sign in is required.<br/>Would you like to sign in?',
      function() {
        mdal.closImgDetlPopUp()
        const imgPath = model.d ? '?d=' + model.d : location.search ? location.search : ''
        location.href =
          '/view/sign/in?r=' + encodeURIComponent(`/view/stock/images/${model.grup.grupId}${imgPath}`)
      },
      '<i class=\'fa-solid fa-check me-2\'></i> Sign in'
    )
  } else {
    emit('showRprtMdal')
  }
}

function setFullScrn(yn) {
  console.log('[IMG-DETL-PANE] set full scrn to', yn)
  const fullScrnElem = document.getElementById('img-ctnt-wrap')
  // 내장 객체임
  if (yn===false) {
    // exitFullscreen is only available on the Document object.
    console.log('Leaving fullscreen mode.')
    fullScrnElem.classList.remove('fullscreen')
    document.exitFullscreen()
  } else {
    fullScrnElem.classList.add('fullscreen')
    fullScrnElem.requestFullscreen()
  }
}

function currItemId(itemId, slctIdx) {
  emit('currItemId', itemId)
  model.slctIdx = slctIdx
}

function toglKywdShow() {
  model.kywdOpen = !model.kywdOpen
}

function onBtnFoldChng(opened) {
  model.kywdOpen = opened
  if (model.kywdOpen === true) {
    const inHegh = document.querySelector('#img-modal-body').clientHeight
    setTimeout(function() {
      document.getElementById('modal-content').scrollTo(0, inHegh)
    }, 100)
  }
}

function showDnldNoti(grupId) {
  const dnldUrl = API_ROOT + `prvt/pcse/dnld/grup/${grupId}`
  axis_cstm()
    .get(dnldUrl, { responseType: 'blob' })
    .then((res) => {
      FileDownload(res.data, `generated-${model.grup.grupId}.zip`)
    })
    .finally(function() {
      setTimeout(function() {
        mdal.stopMainLodr()
      }, 3500)
    })
  console.log(`[DNLD] ${dnldUrl}`)
  mdal.toast(
    t('RQST_DOWNLOAD_WAIT')
  )
}

function onShre() {
  const imgPath = model.d ? "?d=" + model.d : location.search ? location.search : ""
  window.navigator.clipboard
    .writeText(import.meta.env.VITE_APP_HOST + `/view/stock/images/${model.grup.grupId}${imgPath}`)
    .then(() => {
      mdal.toast(
        '<i class="fa-regular fa-copy me-2"></i>The content URL to<br/>share has been copied<br/>to the clipboard'
      )
    })
  // The content URL to share is<br/> copied to the clipboard.
}

function onLike() {
  if (!user.isSignedIn) {
    mdal.confirm(
      "Sign in is required.<br/>Would you like to sign in?",
      function () {
        mdal.closImgDetlPopUp()
        const imgPath = model.d ? "?d=" + model.d : location.search ? location.search : ""
        location.href =
          "/view/sign/in?r=" + encodeURIComponent(`/view/stock/images/${model.grup.grupId}${imgPath}`)
      },
      "<i class='fa-solid fa-check me-2'></i> Sign in"
    )
  } else {
    axis_cstm()
      .post(API_ROOT + "prvt/pmpt/like", { grupId: model.grup.grupId, ctntType: "image" })
      .then((res) => {
        const data = res.data
        if (data.code === ResCd.SUCCESS) {
          if (data.rslt === true) {
            mdal.toast("<i class='fa-solid fa-thumbs-up me-2 text-primary'></i> Added to like!")
            model.grup.liked = true
          } else {
            mdal.toast(
              "<i class='fa-regular fa-trash-xmark me-2 text-danger'></i> Removed from like!"
            )
            model.grup.liked = false
          }
        }
      })
      .catch((err) => {
        console.error("err", err)
      })
      .finally(() => {})
  }
}

function onPcseBtnClck() {
  if (user.isSignedIn && user.isSignedIn===true) {
    //console.log('[MMBR-USER]')
    if (model.grup.purchased) {
      mdal.confirm(
        "This item has been purchased<br/>in whole or in part.<br/>Do you still want to continue?",
        function () {
          emit('onPcseClck')
        },
        '<i class="fa-solid fa-check me-2"></i>OK',
        null,
        '<i class="fa-solid fa-xmark-large me-2"></i>Cancel'
      )
    } else {
      emit('onPcseClck')
    }
  } else {
    console.log('[GUEST-USER]')
    mdal.confirm(
      "We recommend you sign in<br/>before making a purchase.<br/>Do you want to sign in?",
      function () {
        mdal.closImgDetlPopUp()
        const imgPath = model.d ? "?d=" + model.d : location.search ? location.search : ""
        location.href =
          "/view/sign/in?r=" + encodeURIComponent(`/view/stock/images/${model.grup.grupId}${imgPath}`)
      },
      '<i class="fa-solid fa-person-running-fast me-2"></i>Sign in',
      function () {
        // When 2nd button (cancel) clicked
        emit('onPcseClck')
      },
      '<i class="fa-regular fa-user-slash me-2"></i>As a guest'
    )
  }
}

function onQuot() {
  emit('onQuot')
}

defineExpose({ setFullScrn, getThum600Url })
</script>

<style scoped>
.ctnt-wrap {
  background-color: rgb(33, 37, 41);
  height: 100vh;
}

.img-ctnt-wrap {
  background-image: url(@/assets/img/bg/bg-leather-black-big-square.avif);
  background-size: cover;
}

h2.description {
  font-size: 0.9rem !important;
  font-weight: normal;
}

.ic-esrb {
  height: 26px !important;
  margin-top: -8px !important;
}

.cell-kywd + .badge {
  margin: 2px 0 0 0 !important;
}

.cell-kywd .img-wrap svg {
  height: 20px;
  padding-top: 5px;
}

.bdge-rate.teen .icon img {
  height: 21px;
}

.icon .svg-l {
  height: 19px;
}

.table tbody th .badge, .table tbody td .badge {
  background-color: white;
  height: 24px !important;
  padding: 2px 4px 3px 4px !important
}

.imgs-qlty > * {
  float: right;
}

.fa-turn-down-right {
  width: 15px !important;
  height: 15px !important;
}

.fa-tags {
  width: 18px !important;
  height: 18px !important;
}

.table {
  table-layout: fixed;
  background-color: #f3f3f3;
}

.table tbody th .img-wrap, .table tbody td .img-wrap {
  width: 26px;
  height: 26px;
  padding: 0;
  line-height: 26px;
  margin-right: 1px;
  text-align: center;
  vertical-align: middle;
}

.table > * > * .img-wrap > img, .table > * > * .img-wrap > svg {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  text-align: center;
  vertical-align: middle;
}

.table tbody th svg, .table tbody td svg {
  width: 20px;
  height: 20px;
}

.table tbody th .badge {
  border-width: 2px;
  border-color: #999;
}

.table tbody th .badge svg {
  width: 17px;
  height: 17px;
}

.table th {
  text-align: start;
  background-color: #f9f9f9;
}

.table th[colspan="2"] {
  background-color: #ededed;
  border-top: 1px solid #555;
  border-bottom: 1px solid #555;
}

.table.white tbody th:not([colspan="2"]) {
  background-color: #f3f3f3;
}

.table > tbody > tr > td[colspan="2"] {
  text-align: end;
}

.table > * > * > *[colspan="2"] {
  border-top: 1px dashed gray;
}

.table thead td {
  padding-bottom: 0.5rem;
}

.table tbody tr:last-child > * {
  border-bottom: 0;
}

.table * tr > * {
  font-size: 0.8rem !important;
  padding: 4px 8px;
}

.table * tr td:last-child {
  font-size: 0.9rem !important;
}

.td-crtr-msg {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-top: 1px solid gray !important;
  line-height: 1.5 !important;
}

.th-innr {
  vertical-align: middle;
  display: flex;
}

.th-innr * {
  vertical-align: middle;
}

.th-innr .text {
  font-size: 0.8rem;
  font-weight: bold;
}

.bdge-kywd-cnt {
  margin-top: 2px !important;
  border-color: #595959 !important;
  font-weight: bold;
}

.fa-money-check-dollar {
  vertical-align: middle;
  width: 24px !important;
  height: 21px !important;
}

.th-innr-left {
  display: flex;
  flex-grow: 1;
  text-align: start;
  line-height: 29px !important;
}

.th-innr-left .icon {
  margin-right: 5px;
}

.th-innr-left .icon > * {
  height: 22px;
  max-width: 22px;
}

.th-innr .icon.prop-cap, .th-innr .icon.gen-tool {
  padding-top: 0 !important;
}

.th-innr-left .icon svg {
  max-width: 24px;
  margin-top: -7px;
}

.th-innr-right {
  display: flex;
  justify-content: end;
  font-weight: bold;
  height: 22px;
  line-height: 22px;
}

.th-innr .th-innr-right {
  display: flex;
  justify-content: end;
  line-height: 22px;
  height: 22px;
}

.table tbody .th-innr .th-innr-right .badge.rounded-pill {
  min-width: 22px;
  height: 22px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 0.9rem !important;
  line-height: 1;
}

.th-innr .th-innr-right:not(.with-btn) .text {
  padding-top: 1px;
}

.th-innr-right .icon, .th-innr .th-innr-right:not(.with-btn) .text {
  height: 22px !important;
}

.th-innr-right .icon img, .th-innr-right .icon svg {
  height: 20px !important;
}

.btn-dnld svg {
  margin-top: 3px;
}

.font-lg-wrap .text {
  margin-left: 6px;
  font-size: 0.9rem !important;
  color: #0087a3;
  font-weight: 500;
}

.fa-screwdriver-wrench {
  width: 18px !important;
  height: 18px !important;
}

.table tbody * .badge.rounded-pill {
  font-size: 0.78rem !important;
  padding: 2px 6px 2px 6px !important;
  height: 22px !important;
  font-weight: 500;
  line-height: 15px;
}

.kybd-wrap {
  display: none;
}

.kybd-wrap .ic-arow-ud img {
  height: 120px;
}

.kybd-wrap .ic-arow-lr img {
  width: 120px;
}

.col-crtr {
  height: 54px;
  vertical-align: bottom;
  padding-bottom: 4px !important;
}

.table tbody th,
.table tbody td {
  height: 40px;
  padding: 8px 8px;
  line-height: 22px;
  background-color: transparent !important;
}

.table th[colspan="2"] {
  background-color: white !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.bdge-kywd {
  text-transform: lowercase;
}

.crtr-txt-wrap svg {
  margin-top: 5px;
  margin-right: 6px;
}

.note-pcse-item {
  font-size: 0.7rem;
  font-weight: 500;
  background-color: #ffcc00;
  color: #444;
  width: fit-content;
  padding: 1px 4px 1px;
  position: absolute;
  height: fit-content;
  line-height: 1;
  top: 2px;
  right: 6px;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  border-bottom: 1px solid #333;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

th .badge,
.bdge-pmpt {
  cursor: initial;
  font-size: 0.9rem;
  min-width: 26px;
  height: 26px;
  padding: 3px 3px !important;
  line-height: 18px;
  background-color: white;
}

.table {
  letter-spacing: -0.5px;
}

.table tbody > * > * {
  font-size: 1rem;
}

.btn-rept {
  width: 100%;
  padding: 3px 3px;
  line-height: 14px;
  height: 29px;
}

.btn-rept svg {
  color: rgb(0, 135, 163);
  height: 15px !important;
  margin: 0 2px 3px 0 !important;
}

.btn-abs-pos-rt > * > svg {
  height: 100%;
}

.img-cnt {
  min-width: 20px;
  padding-top: 1px;
  padding-bottom: 0;
  border-width: 1px;
}

.row-rels .text {
  line-height: 26px;
}

.row-rels .icon svg {
  margin-top: 5px;
  height: 13px;
  color: #0087a3 !important;
}

.art-title {
  max-height: 100px;
}

.td-kywd-wrap {
  padding: 6px 0 6px 6px !important;
}

.kywd-wrap {
  display: ruby;
}

.table tbody td .kywd-wrap .badge {
  height: 20px !important;
  padding: 1px 4px 0 4px !important;
  margin-bottom: 1px;
}

.table > * > * > *[colspan="2"] {
  border-right: none !important;
}

tr > *[colspan="2"] .th-innr {
  line-height: 22px;
}

.table tbody tr:last-child > * {
  border-bottom: 1px solid gray;
}

/* fullscreen control */

.pc-bttm-area .badge {
  margin-bottom: 0;
  font-size: 0.8rem;
}

.lb-logo-gen8 > img {
  height: 36px;
}

.btn-max > svg {
  height: 26px;
}

@media (max-width: 991px) {

  .img-ctnt-wrap {
    overflow: hidden;
  }

  .row-crtr-info {
    margin-right: 0.5rem;
  }

  .art-title {
    font-size: 1.1rem;
    padding: 0.7rem 0.7rem 0 0.7rem;
  }

  .btn-dnld {
    margin: auto;
  }

  .col-crtr {
    text-align: end;
    padding-left: 0.6rem;
  }

  .fullscreen .kybd-wrap {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 120px;
    transform: translateX(-50%);
  }

  .col-right-img-info-panel {
    background-color: white;
  }
}

@media (max-width: 767.98px) {
  .ctnt-wrap {
    height: calc(100% + 128px);
  }
}

@media (min-width: 768px) {
  .table {
    border-right: none;
  }
}

@media (min-width: 992px) {

  .fullscreen .kybd-wrap {
    display: block;
    position: absolute;
    right: 143px;
    top: 50%;
    transform: translateY(-50%);
  }

  .row-crtr-info {
    margin-right: 42px;
  }

  .col-crtr {
    padding-right: 32px;
  }

  .ctnt-wrap {
    height: 100%;
  }

  .left-cornered h5 {
    padding-left: 0;
  }

  .art-title {
    padding-top: 0.5rem;
    margin-right: 44px;
    word-break: break-all;
  }

  .table-wrap {
    padding-left: 0;
    padding-right: 0;
    min-height: 100%;
  }

  .td-kywd-wrap {
    padding-left: 0.5rem;
    overflow-y: auto;
  }


  .col-left-img-detail-panel {
    position: relative;
    width: 75%;
    max-height: 100%;
    overflow: hidden;
  }

  .col-right-img-info-panel {
    position: relative;
    width: 25%;
    min-width: 340px;
    max-width: 426px;
    max-height: fit-content;
    overflow-y: auto;
    background-color: white;
  }

}

@media (min-width: 1440px) {
}
</style>
