<template>
	<div class="loading-inner" :class="model.theme?model.theme:''">
		<img v-if="model.theme && model.theme.indexOf('white')>-1" :src="whiteLoader" />
		<img v-if="!model.theme || model.theme.indexOf('white')<0" :src="blackLoader" />
		<div v-if="model.hideText===false" class="lodn-text text-center" v-html="model.mesg??'Loading'"></div>
		<div v-if="model.loadPcnt" class="percent" >{{ model.loadPcnt }}</div>
	</div>
</template>

<script setup>
import blackLoader from '@/assets/img/icon/ic-loading-b.webp'
import whiteLoader from '@/assets/img/icon/ic-loading-w.webp'
import { onMounted, reactive, watch } from 'vue'
import { mdal_stor } from '@/stor/mdal_stor'

const mdal = mdal_stor()
const prop = defineProps(['theme', 'mesg', 'hideText', 'stat', 'progPerc'])
const model = reactive({
	mesg: 'Loading',
	theme: 'black',
	loadPcnt: null,
  hideText: false,
  itvlId: null
})

watch(() => prop.mesg,
	(param) => {
		model.mesg = param
})

watch(() => prop.theme,
(param) => {
	model.theme = param
})

watch(
	() => prop.progPerc,
	(newParam, oldParam) => {
		if (newParam === oldParam) {
			return
		}
		model.loadPcnt = newParam
		if (newParam === 33) {
			model.mesg =  'Analyzing'
			model.loadPcnt = 0
			const intervalId = setInterval(function () {
				model.loadPcnt += 1
				if (model.loadPcnt>=100) {
					model.loadPcnt = 100
					clearInterval(intervalId)
				}
			}, 140)
		}
	}
)

onMounted(() => {
	if (prop.mesg) {
		model.mesg = prop.mesg
	}
	if (prop.theme) {
		model.theme = prop.theme
	}
  if (prop.hideText) {
    model.hideText = prop.hideText
  }
})
</script>
<style scoped>
.fs-7 {
  line-height: 1.3;
}
</style>