import { defineStore } from 'pinia'
import { API_ROOT, BrwsStrgKey, ContentGrade, ResCd } from '@/util/comn_cnst'
import { parseJwt, getToknExprTimeInSec, rfshToknSyncFull } from '@/util/auth_func'
import axis_cstm from '@/util/axis_cstm'
import { mdal_stor } from '@/stor/mdal_stor'
import router from '@/rout'

type AncmInfo = {
  notiList: object[],
  unReadNotiCnt: number
}

export const user_stor = defineStore({
	id: 'user',
	state: () => ({
    signIn: <boolean | null> null,
    token: <string | null> null,
    crtrId: <string | null> null,
    showMy: <boolean> false,
    ancmList: <object[] | null> null,
    unReadNotiCnt: <number> 0,
		pmptImgGrupId: <string | null> null,
		pmptImgItemId: <string | null> null,
    rgstStepList: <string[] | null> null,
    rgstUserMail: <string | null> null,
		rfsh: <boolean> false,
		rfshClbk: <Function | null> null,
    innerScrollY: 0,
    cartCnt: 0,
    tp: null,
    pincodeAuthInfo: <string | null> null,
    extendCnt: 0,
    sginBgndImgUrls: null,
    amtManaAvail: 0.0
	}),
	getters: {
    getAmtManaAvail: (state) => {
      return state.amtManaAvail
    },
    isSignedIn: (state) => {
      if (state.signIn) { return state.signIn }
      const jwtStr = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
      state.signIn = !!(jwtStr && jwtStr.length > 9)
      return state.signIn
    },
    getPincodeAuthInfo: (state) => {
      if (state.pincodeAuthInfo) {
        return state.pincodeAuthInfo
      } else {
        const pinUser = localStorage.getItem(BrwsStrgKey.PIN_AUTH_INFO)
        if (pinUser && pinUser.length > 9) {
          localStorage.removeItem(BrwsStrgKey.PIN_AUTH_INFO)
          return pinUser
        }
      }
      return null
    },
    getSginBgndImgUrls: (state) => {
      const urlObj = state.sginBgndImgUrls
      if (urlObj) {
        console.log('G1')
        const urlStr = JSON.stringify(urlObj)
        if (urlStr.length < 46 || urlObj.landUrl==='undefined' || urlObj.portUrl==='undefined') {
          sessionStorage.removeItem("sginBgndImgUrls")
          state.sginBgndImgUrls = null
          return null
        } else {
          const urlObj2 = sessionStorage.getItem("sginBgndImgUrls")
          const urlStr2 = JSON.stringify(urlObj2)
          if (urlStr2.length < 46 || urlObj2.landUrl==='undefined' || urlObj2.portUrl==='undefined') {
            sessionStorage.removeItem("sginBgndImgUrls")
            state.sginBgndImgUrls = null
            return null
          } else {
            return urlObj2
          }
        }
      } else {
        const urlString = sessionStorage.getItem("sginBgndImgUrls")
        const urlObj2 = JSON.parse(urlString)
        console.log('G2', urlString)
        if (urlString && urlString.length > 47) {
          if (urlObj2.landUrl.length > 9 && urlObj2.portUrl.length > 9) {
            return urlObj2
          }
        }
        sessionStorage.removeItem("sginBgndImgUrls")
        state.sginBgndImgUrls = null
        return null
      }
    },
    getFngrPrnt: (state) => {
      return localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
    },
    getUnReadNotiCnt: (state) => {
      return state.unReadNotiCnt
    },
    getAncmList: (state) => {
      if (state.ancmList && state.ancmList.length > 0) {
        return state.ancmList
      } else {
        const ancmData = sessionStorage.getItem(BrwsStrgKey.NOTI_DATA)
        if (ancmData && ancmData.length > 0) {
          return JSON.parse(ancmData).list
        }
      }
    },
    getInnerScrollY: (state) => {
      return state.innerScrollY
    },
		getShowMyInfo: (state) => {
			return state.showMy
		},
		getJwtStr: (state) => {
			return sessionStorage.getItem(BrwsStrgKey.FO_JWT)
		},
		getJwtObjt: (state) => {
			const jwtStr = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
			if (jwtStr && jwtStr.length > 9) {
        return parseJwt(jwtStr)
			} else {
				sessionStorage.removeItem(BrwsStrgKey.FO_JWT)
				return null
			}
		},
		getRole: (state) => {
      const jwtStr = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
			if (jwtStr && jwtStr.length > 9) {
        const jwt = parseJwt(jwtStr)
        // console.log('[getRole] payload', JSON.stringify(jwt))
				return jwt['groups']
			} else {
				return null
			}
		},
		getPhotoUrl: (state) => {
			return sessionStorage.getItem(BrwsStrgKey.USER_PROF_IMG)
		},
		getDispName: (state) => {
			return sessionStorage.getItem(BrwsStrgKey.USER_NAME)
		},
		getCreator: (state) => {
			return (state.crtrId && state.crtrId.length > 0) ? state.crtrId : null
		},
		isRefreshing: (state) => {
			return state.rfsh
		},
		getRefreshingCallBack: (state) => {
			return state.rfshClbk
		},
		getUserId: (state) => {
      const jwtStr = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
      if (jwtStr && jwtStr.length > 9) {
        const jwt = parseJwt(jwtStr)
        return jwt['userId']
      } else {
        return null
      }
		},
		getCartCnt: (state) => {
			return state.cartCnt
		},
	},
	actions: {
    setSginBgndImgUrls (urlObj) {
      this.sginBgndImgUrls = urlObj
      sessionStorage.removeItem("sginBgndImgUrls")
      sessionStorage.setItem("sginBgndImgUrls", JSON.stringify(urlObj))
    },
    getCtntViewGrad () {
      const ret = []
      const jwtStr = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
      if (jwtStr && jwtStr.length > 9) {
        // Todo: JWT 토큰 세션 만료시간 체크 - refresh or
        const jwt = parseJwt(jwtStr)
        const roleArr = jwt['groups']
        if (roleArr) {
          roleArr.forEach((it: string) => {
            if (it.startsWith('MAAG') && it !== ContentGrade.EVERYONE) {
              ret.push(it)
            }
          })
        }
      }
      ret.push(ContentGrade.EVERYONE)
      return ret
    },
    goToSignInToRetn() {
      router.push('/view/sign/in?r=' + encodeURIComponent(location.href))
    },
    setPincodeAuthInfo(pincodeAuthInfo: string | null) {
      localStorage.removeItem(BrwsStrgKey.PIN_AUTH_INFO)
      if (pincodeAuthInfo!=null && pincodeAuthInfo.length > 9) {
        localStorage.setItem(BrwsStrgKey.PIN_AUTH_INFO, pincodeAuthInfo)
        this.pincodeAuthInfo = pincodeAuthInfo
      }
    },
		showMyInfo(value: boolean) {
			this.showMy = value
		},
		setPhtoUrl(value: string) {
			if (value) {
				sessionStorage.setItem(BrwsStrgKey.USER_PROF_IMG, value)
			}
		},
    onSginSucc(rslt: Record<string, unknown>) {
      const tf = <string>rslt.accsTokn
      if (tf && tf.length > 9) {
        this.signIn = true
        sessionStorage.removeItem(BrwsStrgKey.FO_JWT)
        sessionStorage.setItem(BrwsStrgKey.FO_JWT, tf)
        localStorage.setItem(BrwsStrgKey.STOP_TOKN_RQST, "1") // 1 = false
      } else {
        this.signIn = false
        sessionStorage.removeItem(BrwsStrgKey.FO_JWT)
        console.warn('[USER_STOR] failed set token. can not set null value.')
        return
      }
      let sourcePath = new URLSearchParams(window.location.search).get("r")
      if (!sourcePath || sourcePath === '/view/sign/up' || sourcePath === '/view/sign/in') {
        sourcePath = '/'
      }
      sessionStorage.setItem(BrwsStrgKey.USER_PROF_IMG, <string>rslt.thumUrl)
      sessionStorage.setItem(BrwsStrgKey.USER_NAME, <string>rslt.dispName)
      sessionStorage.setItem(BrwsStrgKey.FIRST_SIGN_IN, 'true')
      location.href = (sourcePath || "/")
    },
		showCrtr(crtrId: string) {
			this.crtrId = crtrId
		},
    setTokn(tokn: string) {
      if (tokn && tokn.length > 9) {
        this.signIn = true
        sessionStorage.removeItem(BrwsStrgKey.FO_JWT)
        sessionStorage.setItem(BrwsStrgKey.FO_JWT, tokn)
        localStorage.setItem(BrwsStrgKey.STOP_TOKN_RQST, "1") // 1 = false
        localStorage.removeItem(BrwsStrgKey.FO_JWT)
      } else {
        this.signIn = false
        sessionStorage.removeItem(BrwsStrgKey.FO_JWT)
        localStorage.removeItem(BrwsStrgKey.STOP_TOKN_RQST)
        localStorage.removeItem(BrwsStrgKey.FO_JWT)
      }
    },
    setUserInfo(sginRslt: any) {
      // 1. 토큰
      if (sginRslt.accsTokn && sginRslt.accsTokn.length > 9) {
        this.signIn = true
        sessionStorage.removeItem(BrwsStrgKey.FO_JWT)
        sessionStorage.setItem(BrwsStrgKey.FO_JWT, sginRslt.accsTokn)
        localStorage.setItem(BrwsStrgKey.STOP_TOKN_RQST, "1") // 1 = false
        localStorage.removeItem(BrwsStrgKey.FO_JWT)
      } else {
        this.signIn = false
        sessionStorage.removeItem(BrwsStrgKey.FO_JWT)
        localStorage.removeItem(BrwsStrgKey.STOP_TOKN_RQST)
        localStorage.removeItem(BrwsStrgKey.FO_JWT)
      }
      sessionStorage.setItem(BrwsStrgKey.USER_PROF_IMG, sginRslt.thumUrl)
      sessionStorage.setItem(BrwsStrgKey.USER_NAME, sginRslt.dispName)
      // 알림
      if (!sginRslt.notiList || sginRslt.notiList.length === 0) {
        const noNotiList = [
          {
            id: 0,
            notiMesg: "There're no announcement messages",
            notiType: 'EMPTY',
            readDttm: '1999-12-31T00:00:00.000Z',
            rgstDttm: null
          }
        ]
        sginRslt.notiList = noNotiList
      }
      this.ancmList = sginRslt.notiList
      this.unReadNotiCnt = sginRslt.unReadNotiCnt
      this.cartCnt = sginRslt.cartCnt
      // sessionStorage.setItem(BrwsStrgKey.NOTI_DATA, JSON.stringify({ } }))
    },
    isLcalToknExpd() {
      const timeLeft = getToknExprTimeInSec()
      if (timeLeft <= 0) {
        return true
      }
      const minLeft = Math.floor(timeLeft/60)
      const url = location.pathname
      console.log(`[LOOP][CHCK-TOKNEXPR] ${minLeft}m left`)
      //console.log('pathname', url)
      if (url.startsWith('/view/rgst') && minLeft < 25) {
        rfshToknSyncFull()
        this.extendCnt++
        console.log(`[SESS-EXTD] on regist page auto-extend : ${this.extendCnt} time(s)`)
        if (this.extendCnt > 39) {
          // 등록 중에는 4시간까지 자동 연장
          this.extendCnt = 0
          return true
        }
        return false
      }
      return false
    },
    cnfmApiSignOutThenGoMain() {
      if (!this.isSignedIn) {
        return
      }
      const that = this
      mdal_stor().confirm(
        "Do you really want to sign out?",
        function() {
          axis_cstm()
            .patch(API_ROOT + `prvt/user/revk`, {})
            .then((res) => {
              if (res.data.code==ResCd.SUCCESS && res.data.rslt===true) {
                console.log('[SRVR-SGOT] SUCCESS')
              }
            })
            .catch((e) => {
              console.error('[TOKN-RVOK] err', e.message)
            })
            .finally(() => {
              that.clerUserDataAndGoMain(undefined, undefined)
            })
        },
        '<i class="fa-solid fa-check me-2"></i> Sign out'
      )
    },
    chckToknExprThenSignOut(msg: string | undefined) {
      try {
        if (this.isLcalToknExpd()) {
          this.clerUserDataAndGoMain(msg, true)
        }
      } catch (e) {
        console.error('[TOKN-CHCK] err', e)
      }
    },
    clerUserData() {
      this.signIn = false
      // rgstPaneToglOpen
      const tp = localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
      const togl = localStorage.getItem(BrwsStrgKey.TOGL_OPEN_README_AT_RGST)
      localStorage.clear()
      sessionStorage.clear()
      if (tp && tp.length > 9) {
        localStorage.setItem(BrwsStrgKey.FNGRPRNT_PLUS, tp)
      }
      if (togl && (togl === 'true' || togl === 'false')) {
        localStorage.setItem(BrwsStrgKey.TOGL_OPEN_README_AT_RGST, togl)
      }
    },
    clerUserDataAndGoMain(msg: string | undefined, retnHome: boolean | undefined) {
      // try {
      //   fbseAuth.signOut()
      // } catch(e) {
      //   console.warn('[TOKN-EXPD][CLCK]')
      // }
      this.signIn = false
      // rgstPaneToglOpen
      const tp = localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
      const togl = localStorage.getItem(BrwsStrgKey.TOGL_OPEN_README_AT_RGST)
      localStorage.clear()
      sessionStorage.clear()
      if (tp && tp.length > 9) {
        localStorage.setItem(BrwsStrgKey.FNGRPRNT_PLUS, tp)
      }
      if (togl && (togl==='true' || togl==='false')) {
        localStorage.setItem(BrwsStrgKey.TOGL_OPEN_README_AT_RGST, togl)
      }
      router.go(0)
      /*
      if (retnHome) {
        location.href = "/"
      } else {
        location.href = "/view/sign/in?r=" + encodeURIComponent(location.pathname)
      }
      */
      /*
      if (msg) {
        mdal_stor().alert("You were signed out<br/>after 30 minutes of no contact.")
      }
      */
    },
    setAmtManaAvail(val: Number) {
      this.amtManaAvail = val
    },
		setCartCnt(value: number) {
			this.cartCnt = value
		},
    setAncmInfo(ancmInfo: AncmInfo | null) {
      if (ancmInfo!=null && ancmInfo.notiList != null) {
        if (ancmInfo.notiList.length === 0) {
          const emtyNotiItem = [
            {
              id: 0,
              notiMesg: "There're no announcement messages",
              notiType: 'EMPTY',
              readDttm: '1999-12-31T00:00:00.000Z',
              rgstDttm: null
            }
          ]
          ancmInfo.notiList = emtyNotiItem
        }
        this.ancmList = ancmInfo.notiList
        this.unReadNotiCnt = ancmInfo.unReadNotiCnt
        sessionStorage.setItem(BrwsStrgKey.NOTI_DATA, JSON.stringify(ancmInfo))
      } else {
        this.ancmList = null
        this.unReadNotiCnt = 0
        sessionStorage.removeItem(BrwsStrgKey.NOTI_DATA)
      }
    },
    setUnreadNotiCnt(val: number) {
      const ancmData = sessionStorage.getItem(BrwsStrgKey.NOTI_DATA)
      if (ancmData && ancmData.length > 0) {
        let jwt = JSON.parse(ancmData)
        jwt.cnt = val
        sessionStorage.removeItem(BrwsStrgKey.NOTI_DATA)
        sessionStorage.setItem(BrwsStrgKey.NOTI_DATA, JSON.stringify(jwt))
      }
      this.unReadNotiCnt = val
    },
		updtCartCnt(value: number) {
			this.cartCnt += value
		}
	}
})
