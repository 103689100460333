<template>

	<div class="ctnt-wrap bg-dark">

    <transition v-if="model.data" class="pane-slider" name="slide-left">
      <!-- left col : choice wrap -->
      <div class="left-col" v-show="isMobile()===false || (isMobile()===true && model.step===2)">
        <img-work-item-choc-pane :data="model.data" @onCartChng="onCartChng"
            :step="model.step" />
      </div>
      <!-- left col : choice wrap -->
    </transition>
    <transition v-if="model.step===3" class="pane-slider" name="slide-right">
      <!-- right col : payment pane -->
      <div class="right-col">
        <pamt-strp-pane ref="pamtPane" :data="model.payData" @loading="setLoading" @closMdal="closMdal" />
        <spnr-no-block v-if="model.loading" theme="absolute white" />
      </div>
      <!-- right col : payment pane -->
    </transition>

    <!-- button area : purchase step 2 and 3 -->
    <abs-bttm-btns-area
      v-if="model.step===2 || model.step===3"
      :btnUse="model.btnUse"
      :disabled="model.disabled"
      :step="model.step"
      class="p-w-50 left-adaptive position-fixed"
      @onAddCart="emit('onAddCart')"
      @onCheckOut="onStep2NextClick"
      @onPrev="onPrev"
    />
    <!-- button area : purchase step 2 and 3 -->

	</div>
	<!-- image description column -->
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue'
import PamtStrpPane from '@/view/pane/PamtStrpPane.vue'
import ImgWorkItemChocPane from '@/view/pane/ImgItemChocPane.vue'
import AbsBttmBtnsArea from '@/view/btns/AbsBttmBtnsArea.vue'
import { isMobile } from '@/util/comn_func'
import SpnrNoBlock from '@/view/pgrsAlrt/SpnrNoBlock.vue'

const prop = defineProps(['data', 'step', 'payData', 'releaseUi', 'loading'])
const emit = defineEmits(['onCartChng', 'onStep2NextClick', 'onPrev', 'onAddCart', 'closMdal'])
const pamtPane = ref()
const btnStp2 = ['ckot', 'prev', 'cart']
const btnStp3 = ['prev']
const model = reactive({
  step: null,
  loading: false,
	data: null,
  cartData: null,
  disabled: true,
  btnUse: btnStp2,
})

watch(() => prop.step,
	(param) => {
    model.step = param
    //console.log('step', param)
    if (model.step===2) {
      model.btnUse = btnStp2
    } else if (model.step===3) {
      model.loading = true
      model.btnUse = btnStp3
    }
})

watch(() => prop.payData,
	(param) => {
	model.payData = param
})

watch(() => prop.loading,
  (param) => {
    model.loading = param
  })

onMounted(() => {
  getParamFromPrevPane()
})

function getParamFromPrevPane() {
  model.step = prop.step
  model.data = prop.data
}

function setLoading(val) {
  model.loading = val
}

function closMdal(forceClose) {
  emit('closMdal', forceClose)
}

function onStep2NextClick() {
  model.loading = true
  emit('onStep2NextClick')
}

function onCartChng(data) {
  model.cartData = data
  const imgCnt  = model.cartData.imgCnt?model.cartData.imgCnt:0
  const pmptCnt = model.cartData.pmptCnt?model.cartData.pmptCnt:0
  if ((imgCnt + pmptCnt) > 0) {
    model.disabled = false
  } else {
    model.disabled = true
  }
  emit('onCartChng', data)
}

function onPrev() {
  emit('onPrev')
}
</script>

<style scoped>

h4 svg {
  margin-top: 5px;
  height: 1.5rem; margin-right: 6px;
}
.left-col {
  position: absolute;
  height: calc(100% - 63px);
  left: 0;
  padding: 0;
}
.right-col {
  overflow-y: auto;
}

.left-adaptive {
  left: 0;
}

@media (max-width: 991px) {
  .p-w-50 {
    width: 100%;
  }
  .ctnt-wrap {
    width: 200%;
  }
  .left-col {
    width: 100%;
  }
  .right-col {
    top: 0;
    left: 0;
    padding: 0;
    height: 100%;
  }

  .slide-left-leave-active,
  .slide-left-enter-active,
  .slide-right-leave-active,
  .slide-right-enter-active {
    transition: 1s;
  }

  .slide-left-enter-from {
    transform: translateX(-100%);
  }
  .slide-left-leave-to {
    transform: translateX(-100%);
  }
  .slide-right-enter-from {
    transform: translateX(100%);
  }
  .slide-right-leave-to {
    transform: translateX(100%);
  }
}

@media (min-width: 992px) {
  .ctnt-wrap {
    width: 100%;
    display: flex;
  }
  .p-w-50 {
    width: 50%;
  }
  .pane-slider {
    display: flex;
  }
  .left-col {
    position: relative;
    width: 50%;
	}
	.right-col {
		width: 50%;
    position: relative;
    overflow-y: hidden;
	}
  .slide-left-enter-from {
    transform: none;
  }
  .slide-left-leave-to {
    transform: none;
  }
  .slide-right-enter-from {
    transform: none;
  }
  .slide-right-leave-to {
    transform: none;
  }
}

@media (min-width: 992px) {
}

</style>
