<template>
  <div class="btn-abs-bottom-pc-right btn-area p-2 d-sm-p-4px flexible m-brdr"
        :class="model.addClas">
    <div class="option-box d-flex justify-content-between">
      <a
        @click="$emit('onCancel')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md"
        v-if="prop.btnUse.includes('cncl')"
      >
        <i class="fa-solid fa-xmark" /><span class="d-none d-md-block">Cancel</span></a>
      <a
        @click="$emit('onFullScrnClck')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md"
        v-if="prop.btnUse.includes('maxm')"
      >
        <i class="fa-sharp fa-regular fa-arrows-maximize"></i>
      </a>

      <a
        @click="$emit('onList')"
        class="btn-list btn btn-lg btn-flex btn-outline btn-outline-light shadow-md"
        v-if="prop.btnUse.includes('list')"
      >
        <i class="fa-regular fa-list-ol" /><span class="d-none d-md-block btn-nm">List</span>
      </a>
      <a
        @click="$emit('onPrev')"
        class="me-2 btn btn-lg btn-flex btn-outline btn-outline-light shadow-md"
        v-if="prop.btnUse.includes('prev')"
        title="Previous"
      >
        <i class="fa-solid fa-chevron-left" /><span class="d-none d-md-block btn-nm">Prev</span></a
      >

      <div v-if="!prop.hideCnclBtn" class="me-auto btn-div"></div>

      <a
        @click="$emit('onReject')"
        class="btn btn-lg btn-flex btn-outline btn-primary shadow-md"
        v-if="prop.btnUse.includes('rejt')"
        title="Reject"
      >
        <i class="fa-solid fa-ban" /><span>Reject</span></a
      >
      <a
        @click="$emit('onDiscard')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md btn-dscd"
        v-if="prop.btnUse.includes('dscd')"
        :class="model.disabled === true ? 'disabled' : ''"
        title="Discard"
      >
        <i class="fa-solid fa-trash-can" /><span class="d-none d-md-block btn-nm">Discard</span></a
      >
      <a
        @click="$emit('onRemove')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md"
        v-if="prop.btnUse.includes('remv')"
        title="Remove"
      >
        <i class="fa-solid fa-trash-can" /><span>Remove</span></a
      >
      <a
        @click="$emit('onExcel')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md"
        v-if="prop.btnUse.includes('excl')"
        title="Excel"
      >
        <i class="fa-solid fa-download" /><span>Excel</span></a
      >
      <a
        @click="$emit('onPass')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md"
        v-if="prop.btnUse.includes('pass')"
        title="Pass turn"
      >
        <i class="fa-solid fa-jet-fighter" />Pass<span class="d-none d-md-block btn-nm"
          >decision</span
        ></a
      >

      <a
        @click="$emit('onQuot')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md btn-shre"
        v-if="prop.btnUse.includes('quot')"
      >
        <i class="fa-regular fa-quotes"></i>
<!--        <span class="d-none d-md-block btn-nm">Quot</span>-->
      </a>

      <a
        @click="$emit('onShre')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md btn-shre"
        v-if="prop.btnUse.includes('shre')">
        <i class="fa-sharp fa-regular fa-share-nodes"></i>
<!--        <span class="d-none d-md-block btn-nm">Share</span>-->
      </a>

      <a
        @click="$emit('onApprove')"
        class="btn btn-lg btn-flex btn-outline btn-primary shadow-md"
        v-if="prop.btnUse.includes('aprv')"
        title="Approve"
      >
        <i class="fa-solid fa-stamp" /><span>Approve</span></a
      >
      <a
        @click="$emit('onInvite')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md"
        v-if="prop.btnUse.includes('invt')"
        title="Invite"
      >
        <i class="fa-solid fa-plus" /><span>Invite</span></a
      >
      <a
        @click="$emit('onLike')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md btn-like"
        v-if="prop.btnUse.includes('like')"
        :class="prop.liked ? 'btn-pink' : ''"
        title="Add">
        <i class="fa-regular fa-thumbs-up"></i>
<!--        <span class="d-none d-md-block btn-nm">Like</span>-->
      </a>
      <a
        @click="$emit('onAdd')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md"
        v-if="prop.btnUse.includes('add')"
        title="Add"
      >
        <i class="fa-solid fa-plus" /><span>>Add</span></a
      >
      <a
        @click="$emit('onAddCart')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md btn-cart"
        :class="model.disabled === true ? 'disabled' : ''"
        v-if="prop.btnUse.includes('cart')"
      >
        <span><i class="fa-regular fa-cart-shopping-fast"></i></span>
        <span class="d-none d-md-block btn-nm">Cart</span>
      </a>
      <a
        @click="$emit('onGoCart')"
        class="btn btn-lg btn-flex btn-outline shadow-md"
        v-if="prop.btnUse.includes('gcrt')"
        :class="model.disabled === true ? 'disabled' : ''"
      >
        <i class="fa-solid fa-cart-shopping" /><span>Go to order</span></a
      >
      <a
        @click="$emit('onSave')"
        class="btn btn-lg btn-flex btn-outline btn-outline-light shadow-md btn-dscd"
        v-if="prop.btnUse.includes('save')"
        :class="model.disabled === true ? 'disabled' : ''"
        title="Save"
      >
        <i class="fa-regular fa-floppy-disk" /><span class="d-none d-md-block btn-nm">Save</span></a
      >
      <a
        @click="$emit('onRegister')"
        class="btn btn-lg btn-flex btn-outline btn-primary shadow-md"
        v-if="prop.btnUse.includes('rgst')"
        :class="model.disabled === true ? 'disabled' : ''"
        title="Register"
      >
        <i class="fa-solid fa-check" /><span>Register</span></a
      >
      <a
        @click="$emit('onUnderstandConfirm')"
        class="text-center btn btn-lg btn-cnfm-prcd ms-0 btn-flex justify-content-center btn-outline btn-primary shadow-md no-w-lim"
        v-if="prop.btnUse.includes('undrCnfm')"
        :class="model.disabled === true ? 'disabled' : ''"
        title="Confirm"
      >
        <span>I Understand and Will Proceed</span><i class="ms-2 fa-solid fa-angles-right"></i>
      </a>
      <a
        @click="$emit('onSubmit')"
        class="btn btn-lg btn-submit btn-flex btn-outline btn-primary shadow-md"
        v-if="prop.btnUse.includes('sbmt')"
        :class="model.disabled === true ? 'disabled' : ''"
        title="Submit"
      >
        <i class="fa-solid fa-plane-departure" /><span>Submit</span></a
      >
      <a
        @click="$emit('onPcseBtnClck')"
        class="btn btn-lg btn-flex btn-outline btn-primary shadow-md btn-pcse"
        v-if="prop.btnUse.includes('pcse')"
      >
        <div class="d-flex btn-innr-wrap">
          <i class="fa-regular fa-credit-card"></i>
          <div class="ms-2 d-block d-md-none d-lg-block">Pay</div>
        </div>
      </a>
      <a
        @click="$emit('onCartPurchase')"
        class="btn btn-lg btn-flex btn-outline btn-primary shadow-md"
        v-if="prop.btnUse.includes('cartPcse')"
      ><span>Purchase</span><i class="ms-2 icon-next fa-solid fa-angle-right"
      /></a>
      <a
        @click="$emit('onCheckOut')"
        class="btn btn-lg btn-flex btn-outline btn-primary shadow-md"
        :class="(model.disabled || model.step === 3 ? ' disabled' : '')"
        v-if="prop.btnUse.includes('ckot')">
        <span class="text">
          Check out
        </span>
        <span class="ms-3">
          <i class="icon-next fa-solid fa-angle-right" />
        </span>
      </a>
      <a
        @click="$emit('onNext')"
        :class="
          'btn btn-lg btn-flex btn-primary btn-outline shadow-md text-end' +
          (model.disabled || model.step === 3 ? ' disabled' : '')
        "
        v-if="prop.btnUse.includes('next')"
        title="Next"
      >
        <span>Next</span>
        <span class="ms-2">
          <i class="icon-next fa-solid fa-angle-right"></i>
        </span>
      </a>
    </div>
  </div>
</template>

<script setup>
import { watch, reactive, onMounted } from "vue"
import { isMobile } from '@/util/comn_func'

const emit = defineEmits([
  "onRemove",
  "onPrev",
  "onExcel",
  "onReject",
  "onApprove",
  "onInvite",
  "onRegister",
  "onSave",
  "onAddCart",
  "onAdd",
  "onDiscard",
  "onCancel",
  "onPcseBtnClck",
  "onNext",
  "onSubmit",
  "onLike",
  "onList",
  "onShre",
  "onGoCart",
  "onPay",
  "onQuot",
  "onCheckOut"
])
const prop = defineProps(["btnUse", "disabled", "liked", "hideCnclBtn", "step"])
const model = reactive({
  disabled: false,
  addClas: ''
})

watch(
  () => prop.disabled,
  (newParam) => {
    model.disabled = newParam
  }
)

watch(
  () => prop.step,
  (newParam) => {
    model.step = newParam
    if (model.step === 3 && isMobile()) {
      model.addClas = 'btn-bg-none'
    } else {
      model.addClas = ''
    }
  }
)

onMounted(() => {
  if (prop.disabled) {
    model.disabled = prop.disabled
  }
})
</script>

<style scoped>
.btn-abs-bottom-pc-right {
  height: 64px;
}
.text {
  line-height: 1.1;
}
.btn-lg.btn-outline:not(.btn-primary) {
  background-color: white;
}
.no-w-lim svg {
  margin-right: 0 !important;
}

.btn-cnfm svg {
  margin-left: 0.5rem;
  margin-right: 0;
}
.btn-like {
  padding: 8px 9px 10px !important;
}
.btn-shre {
  padding: 9px 11px 9px 7px !important;
}
.btn-lg.btn-primary {
  padding-left: 12px;
  padding-right: 12px;
  flex-grow: 1;
}
.btn-submit {
  min-width: 150px !important;
}
.btn-cnfm-prcd {
  min-width: fit-content;
  padding: 9px 18px !important;
  width: 100%;
  text-align: center;
}

@media (max-width: 991px) {
  .bottom-btn-area.m-brdr {
    width: calc(100% - 12px);
    left: 6px;
  }
  .no-w-lim {
    max-width: 100%;
  }

  .fix-trans {
    position: fixed;
  }

  .btn-cart {
    padding: 8px 0 0 5px !important;
  }

}

@media (min-width: 992px) {

  .btn-cart {
    padding: 8px 8px 0 8px !important;
  }

  .btn-abs-bottom-pc-right {
    position: sticky;
    width: 100%;
    bottom: 0;
  }

  .bottom-btn-area .btn.btn-lg.btn-primary svg {
    margin: 0 0.3rem 0 !important;
  }

  .btn svg _ span {
    padding-top: 1px;
  }
}

@media (min-width: 1400px) {
  .bottom-btn-area.flexible {
    width: 25%;
  }
}

@media (min-width: 1920px) {
  .bottom-btn-area.flexible {
    width: 18%;
  }

  .bottom-btn-area.flexible .btn-list {
    display: block !important;
  }
}
</style>
