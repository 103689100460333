<template>
  <ul class="dropdown-menu shadow-smd1" :aria-labelledby="prop.labl">
    <template v-for="(item, idx) in list" :key="idx">
      <li
        :class="item.readDttm === null ? 'bg-yello-note' : ''"
        class="overflow-x-hidden"
      >
        <a class="dropdown-item overflow-x-hidden" @click="readNoti(item.id)">
          <div class="bdge-date badge bg-white me-1"
               :class="isoDateToShortNoYear(item.rgstDttm).indexOf(':')>0?'text-navy-bold':''">
            {{ isoDateToShortNoYear(item.rgstDttm) }}
          </div>
          <span
            v-if="item.notiType === NotiType.PAOT_CPLT_PAPL"
            class="badge ic-vendor bg-papl me-1">
          </span>
          <span
            v-if="item.notiType === NotiType.PAOT_CPLT_STRP"
            class="badge ic-vendor bg-strp me-1">
          </span>
          <span v-if="item.notiType === NotiType.WORK_APRV" class="icon-evnt badge bg-white me-1">
            <i class="fa-regular fa-stamp"></i>
          </span>
          <span v-if="item.notiType === NotiType.WORK_REJT" class="icon-evnt badge bg-white me-1">
            <i class="fa-solid fa-ban"></i>
          </span>
          <span v-if="item.notiType === NotiType.WORK_REWD" class="icon-evnt badge bg-white me-1">
            <i class="fa-regular fa-gift"></i>
          </span>
          <div class="ellipsis lh-20">{{ item.notiMesg }}</div>
        </a>
      </li>
    </template>
  </ul>
</template>

<script setup>
import { isoDateToShortNoYear } from '@/util/comn_util'
import { API_ROOT, NotiType, ResCd } from '@/util/comn_cnst'
import axis_cstm from '@/util/axis_cstm'
import { user_stor } from '@/stor/user_stor'

const prop = defineProps(['labl', 'list'])
const user = user_stor()

function readNoti(notiId) {
  if (notiId === 0) {
    return
  }
  console.log('notiId', notiId)
  axis_cstm()
    .patch(API_ROOT + "prvt/my/noti", { notiId: notiId })
    .then((res) => {
      const data = res.data
      console.log('read res data', JSON.stringify(data))
      if (data.code === ResCd.SUCCESS && data.rslt) {
        if (data.rslt.notiList.length > 0) {
          user.setAncmInfo(data.rslt)
          user.setUnreadNotiCnt(user.getUnReadNotiCnt - 1)
        } else {
          user.setUnreadNotiCnt(0)
          const noNotiList = [
            {
              id: 0,
              notiMesg: "There're no announcement messages",
              notiType: "EMPTY",
              readDttm: "1999-12-31T00:00:00.000Z",
              rgstDttm: null
            }
          ]
          let notiObj = {
            list: noNotiList,
            cnt: 0
          }
          user.setAncmInfo(notiObj)
        }
      }
    })
    .catch((err) => {
      console.log(`${err.name} ${err.code} ${err.message}`)
    })
    .finally(() => {

    })
}
</script>


<style scoped>

.mobl-icon .nav-item.cart > .dropdown-toggle > svg {
  line-height: 1;
}

.dropdown-menu li + li {
  border-top: 1px dashed #ccc;
}

.dropdown-item {
  font-size: 0.8rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  text-transform: none;
  display: flex;
}

.dropdown-item .badge {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.bg-yello-note {
  background-color: #fdf5adcc;
}

.bdge-date {
  min-width: 54px;
}

.ic-vendor {
  display: block !important;
  min-width: 54px;
  height: 20px;
  background-repeat: no-repeat;
  background-color: white;
}

.bg-papl {
  background-image: url("@/assets/img/icon/btn-papl.webp");
  background-size: 90%;
  background-position: center 3px;
}

.bg-strp {
  background-image: url("@/assets/img/icon/btn-strp.webp");
  background-size: 75%;
  background-position: center 2px;
}

.lh-20 {
  line-height: 20px;
}

@media (max-width: 991px) {

}

@media (min-width: 992px) {

}
</style>