<template>
  <div class="left-wrap position-relative">

    <div v-if="model.grup" class="left-inner d-flex flex-column">

      <!-- prompt row -->
      <div class="pmpt-wrap pt-3 px-3">
        <!-- image area title -->
        <div class="titl-wrap img d-flex mx-1 mt-2">
          <div class="flex-grow-1 d-flex">
            <i class="fs-3 fa-regular fa-rectangle-terminal me-2" />
            <h4 class='img-page text-white me-2'>Prompt</h4>
            <div class="small text-primary"> Click to add cart</div>
          </div>
        </div>
        <!-- image area title -->

        <!-- prompt display column -->
        <div class="pmpt-icon-area noselect w-100 mx-1 mt-2 shadow-smd1 position-relative d-flex"
          role="button" @click="onAddCart('PMPT', model.grup.pricIdPmpt)"
          :class="model.cart.pmptCnt===1?'active':''">
          <div class="icon-wrap text-center">
            <i class="fa-regular fa-rectangle-terminal" />
          </div>
          <div class="text-wrap">
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</span>
            <span class="d-none d-md-block"> ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</span>
            <span class="d-none d-lg-block">Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto.</span>
          </div>
          <!-- checked icon -->
          <a class="pos-abs-center text-center" role="button" v-if="model.cart.pmptCnt===1">
            <i class="fa-solid fa-check text-highlight shadow-sm" />
          </a>
          <!-- checked icon -->
        </div>
      </div>
      <!-- prompt row -->

      <!-- image grid row -->
      <div class="img-cont-wrap px-3 flex-grow-1 position-relative">

        <!-- image area title -->
        <div class="titl-wrap img d-flex mx-1 mt-3">
          <div class="flex-grow-1 d-flex">
            <i class="fs-3 fa-regular fa-images me-2" />
            <h4 class='img-page text-white me-2'>Images</h4>
            <div class="small text-primary"> Click to add cart</div>
          </div>
          <a @click="slctAllImgs" class="btn btn-sm btn-outline-white me-1">Select all</a>
        </div>
        <!-- image area title -->

        <div class="img-grid-row row gx-0 gy-0 mt-2">

          <!-- image display column -->
          <template v-if="model.imgList && model.imgList.length > 0">

            <!-- image list loop -->
            <div class="col col-3 h-33" v-for="(item, idx) in model.imgList" :key="idx">

              <div class="thumbnail" @click="onAddCart('IMGS', item.id)"
                   :style="'background-image: url(' + item.pblcThumUrl + ');'"
                   :class="model.imgIds.has(item.id)?'active':''"
                   role="button">
                <a class="pos-abs-center" role="button" v-if="model.imgIds.has(item.id)">
                  <i class="fa-solid fa-check text-highlight shadow-sm" />
                </a>
              </div>
            </div>

            <!-- when images count less than 12 -->
            <template v-if="model.imgList.length<12">
              <div class="col col-3 h-33" v-for="idx in (12 - model.imgList.length)" :key="idx">
                <div class="thumbnail no-img">
                  <img src="@/assets/img/icon/no-img-64-g.webp" />
                </div>
              </div>
            </template>
            <!-- image list loop -->
          </template>
          <!-- image display column -->
        </div>

        <div v-if="model.grup.imgPricAmt===0 && model.cart.pmptCnt===0" class="choice-blocker-sm"></div>

      </div>
      <!-- image grid row -->

      <div v-if="model.step===3" class="choice-blocker"></div>

      <!-- cart pricing row -->
      <div v-if="model.grup" class="cart-area d-flex">
        <table id="yello-note" class="table white">
          <colgroup>
            <col style="width: 53%; " />
            <col style="width: 47%; " />
          </colgroup>
          <thead>
          <tr>
            <th class="text-center txt-totl text-shadow-md">
              Total
            </th>
            <td class="totl-pric text-shadow-md text-end text-primary">
              <div v-if="model.pric.subTotl" class="pe-2">
                {{ model.pric.subTotl }}
              </div>
            </td>
          </tr>
          </thead>
          <tbody>
          <tr class="bb-solid">
            <th>
              <div class="item-cate-wrap">
                <div class="d-flex flex-grow-1">
                  <div class="img-wrap">
                    <i class="fs-3 fa-regular fa-rectangle-terminal me-2" />
                  </div>
                  <div class="txt-th">Prompt</div>
                </div>
                <div class="fw-normal">
                  {{ crcyFmat.format(model.cart.pmptPricAmt / 100) }}
                </div>
              </div>
            </th>
            <td class="fw-bold fs-5">
              <div class="d-inline-flex w-100 justify-content-between img-pric-wrap">
                <div v-if="model.pric.pmptTotl !== null" class="pmpt-chck-wrap bdge-pmpt-cnt badge rounded-pill text-primary">
                  <i class="fa-solid fa-check me-2 text-primary fw-bold"></i>
                </div>
                <div class="text flex-grow-1 fs-5">
                  {{ model.pric.pmptTotl === null ? '-' : crcyFmat.format(model.pric.pmptTotl) }}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="border-bottom-0">
              <div class="item-cate-wrap">
                <div class="img-wrap">
                  <i class="fs-3 fa-regular fa-images me-2" />
                </div>
                <div class="flex-grow-1 txt-th">Images</div>
                <div class="fw-normal">
                  {{ crcyFmat.format(model.cart.imgPricAmt / 100) }}(each)
                </div>
              </div>
            </th>
            <td class="border-bottom-0 fw-bold text-end">
              <div class="d-inline-flex w-100 justify-content-between img-pric-wrap">
                <div v-if="model.imgIds && model.imgIds.size > 0"
                      class="bdge-imgs-cnt badge rounded-pill text-primary text-start d-flex">
                  <div class="icon"><i class="fa-solid fa-xmark"></i></div>
                  <div class="text">{{ model.imgIds.size }}</div>
                </div>
                <div class="text flex-grow-1 fs-5">
                  {{ model.pric.imgTotl === null ? '-' : crcyFmat.format(model.pric.imgTotl) }}
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- cart pricing row -->

    </div>

  </div>

</template>

<script setup>
import { onBeforeUnmount, onMounted, reactive, watch } from 'vue'
import { isMobile } from '@/util/comn_func'

const prop = defineProps(['data', 'step'])
const emit = defineEmits(['onCartChng'])
const model = reactive({
  step: 2,
  rowHeight: 450,
  user: null,
  grup: null,
  imgList: [],
  imgIds: new Set(),
  pmptPricId: null,
  pric: {
    subTotl: null,
    pmptTotl: null,
    imgTotl: null
  },
  cart: {
    ctntType: null,
    grupId: null,
    titl: null,
    crtrId: null,
    crtrNm: null,
    cstmData: null,
    items: [],
    imgPricId: null,
    imgPrdtNm: null,
    pmptPrdtNm: null,
    imgDesc: null,
    pmptDesc: null,
    imgPrdtUrl: null,
    pmptPrdtUrl: null,
    imgIdList: [],
    pmptPricId: null,
    dsctId: null,
    totlPric: 0,
    frmtTotl: 0,
    plfmFeeRate: 0,
    imgCnt: 0,
    pmptCnt: 0,
    usdPric: 0.0
  }
})
let crcyFmat

watch(() => prop.step,
  (param) => {
    console.log('last step', param)
    model.step = param
  })

onMounted(() => {
  getParams()
})

onBeforeUnmount(() => {
  document.getElementById('yello-note').removeEventListener('touchmove', onDrag, false)
})

function onDrag(e) {
  console.log('onDrag')
  e.stopPropagation()
  e.preventDefault()
}

function getParams() {
  model.step = prop.step
  model.user = prop.data.user
  model.grup = prop.data.grup
  model.imgList = prop.data.imgList
  // cart
  model.cart.titl = model.grup.titl
  model.cart.crtrId = model.grup.crtrId
  model.cart.crtrNm = model.grup.crtrNm
  model.cart.grupId = model.grup.grupId
  model.cart.ctntType = model.grup.ctntType
  model.cart.imgPricId = model.grup.pricIdImg
  model.cart.pmptPricId = model.grup.pricIdPmpt
  model.cart.imgPricAmt = model.grup.imgPricAmt
  model.cart.pmptPricAmt = model.grup.pmptPricAmt

  crcyFmat = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD'
  })

}

function slctAllImgs() {
  // console.log('s', model.imgIds.size)
  if (model.imgIds.size === model.imgList.length) {
    model.imgIds.clear()
  } else {
    model.imgList.forEach((it) => {
      model.imgIds.add(it.id)
    })
  }
  aftrAddCart()
}

function onAddCart(type, id) {

  if (type === 'IMGS') {
    if (model.imgIds.has(id)) {
      model.imgIds.delete(id)
    } else {
      model.imgIds.add(id)
    }
    // console.log('model.imgIds', [...model.imgIds].join(' '))
  } else if (type === 'PMPT') {
    if (model.cart.pmptCnt === 0) {
      model.cart.pmptCnt = 1
    } else {
      model.cart.pmptCnt = 0
      // model.imgIds.clear()
    }
  }
  aftrAddCart()
}

function aftrAddCart() {

  model.cart.imgCnt = model.imgIds.size
  model.cart.imgIdList = Array.from(model.imgIds)

  let items = []

  model.cart.dsctId = null
  model.pric.dsctAmt = null
  model.pric.dsctPerc = null

  if (model.cart.imgCnt > 0) {
    items.push({
      priceId: model.cart.imgPricId,
      quantity: model.cart.imgCnt,
      dsctId: model.cart.dsctId
    })
  }

  if (model.cart.pmptCnt === 1) {
    items.push({ priceId: model.cart.pmptPricId, quantity: 1 })
  }
  model.cart.items = items

  if (model.cart.imgCnt < 1) {
    model.pric.imgTotl = null
  } else {
    model.pric.imgTotl = (model.cart.imgPricAmt * model.cart.imgCnt) / 100
  }
  if (model.cart.pmptCnt < 1) {
    model.pric.pmptTotl = null
  } else {
    model.pric.pmptTotl = model.cart.pmptPricAmt / 100
  }
  if ((model.cart.imgCnt + model.cart.pmptCnt) === 0) {
    model.pric.subTotl = null
    model.pric.tax = null
  } else {
    model.pric.subTotl = model.pric.imgTotl + model.pric.pmptTotl
  }

  model.cart.totlPric = model.pric.subTotl
  model.pric.subTotl = crcyFmat.format(model.cart.totlPric)

  emit('onCartChng', model.cart)
}

</script>

<style scoped>
.thumbnail {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-position: center;
  position: relative;
  background-size: cover;
}

.left-wrap {
  position: relative;
  height: 100%;
}

.left-inner {
  width: 100%;
  height: 100%;
}
.table > thead > * > * {
  min-height: 42px;
  height: 42px;
}
thead > * > * {
  font-size: 2.2rem;
  padding: 0 !important;
  line-height: 1;
}

.txt-totl {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: normal;
}

.txt-totl, .totl-pric {
  line-height: 1;
  font-size: 1.6rem;
  padding: 2px 0 4px 0;
  letter-spacing: 2px !important;
  font-weight: 500;
}

.totl-pric {
  color: #ffcc00 !important;
}

.table thead th, .table thead td {
  padding: 0 0.5rem !important;
  background-color: rgba(30, 62, 104, 0.7) !important;
  font-family: 'Spoqa Han Sans Neo', 'sans-serif' !important;
  font-size: 1.45rem !important;
  font-weight: 400;
}

.table > tbody > * {
  height: initial !important;
}

.table > tbody > * > * {
  max-height: 40px;
  min-height: fit-content;
  height: 40px !important;
  padding: 0 8px !important;
}

.table > tbody > tr > th {
  padding-left: 12px !important;
}

.table > tbody > tr > * {
  font-size: 0.9rem;
  color: #595959;
}

.table > tbody > *, .badge {
  font-size: 1rem;
  color: #555;
  font-weight: normal;
}

.table > tbody > *, .badge, .img-pric-wrap {
  line-height: 28px;
}

.pmpt-chck-wrap {
  padding: 0 2px 0 9px !important;
}

.bdge-pmpt-cnt {
  border: 2px solid rgb(0, 169, 204);
  font-weight: bold;
  font-size: 1rem;
  line-height: 25px !important;
}

.bdge-imgs-cnt {
  border: 2px solid rgb(0, 169, 204);
  font-weight: bold;
  font-size: 1rem;
  padding: 1px 5px !important;
  line-height: 28px !important;
}

.bdge-imgs-cnt .icon {
  margin-top: 0;
  margin-right: 1px;
}

.bdge-imgs-cnt .text {
  line-height: 20px;
  font-size: 1.2rem;
}

.badge {
  height: 28px;
  margin-bottom: 0;
  min-width: 20px;
  margin-left: 4px;
  background-color: white;
  line-height: 24px;
  padding: 0 5px;
}

.pmpt-icon-area {
  background-image: url('@/assets/img/bg/bg-leather-brown-square.avif');
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  color: white;
  max-width: 100%;
  padding: 6px 0;
  height: calc(100% - 49px);
  font-size: 0.87rem;
}

.pmpt-wrap {
  height: calc((100% - 188px) / 3);
}

.img-cont-wrap {
  height: calc(100% - 1rem);
  padding: 0.5rem 0 5rem;
}

.img-grid-row {
  height: 100%;
  padding: 3px;
  border-radius: 16px;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.7);
  background-image: url(@/assets/img/bg/bg-leather-black-big-square.avif);
}

.img-grid-row .col {
  padding: 5px;
}

.titl-wrap, .titl-wrap > *, .titl-wrap > * > * {
  color: rgba(255, 255, 255, 0.8);
  line-height: 30px;
}

.titl-wrap > * > svg {
  margin-top: 2px;
  height: 26px;
}

.titl-wrap > .btn {
  line-height: 18px;
}

.titl-wrap .small {
  line-height: 14px;
  padding-top: 10px;
}

.col.col-3:first-child, .col.col-3:nth-child(2), .col.col-3:nth-child(3), .col.col-3:nth-child(4) {
  margin-top: 0;
}

.icon-wrap {
  padding: 0 0.5rem;
  height: 100%;
  width: fit-content;
}

.icon-wrap .fa-rectangle-terminal {
  color: rgba(255, 255, 255, 0.6);
  height: 100%;
  max-width: 85%;
  top: 50%;
}

.text-wrap {
  margin: auto 0;
  color: rgba(255, 255, 255, 0.7);
  max-height: 100%;
  line-height: 1.3;
  padding-left: 0;
  padding-right: 1.1rem;
  word-break: break-all;
}

.pmpt-icon-area > .thumbnail {
  border: 1px solid black;
}

th .d-flex .badge {
  padding: 2px 3px;
  margin-right: 0;
}

.cart-area {
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid white;
}

.h-33 {
  height: 33.33%;
}

.table tbody th, .table tbody td {
  height: 33px;
  padding: 2px 5px 2px 8px;
}

.table > * > *, .table > * > * > *[colspan="2"] {
  border-top: 0;
  padding-bottom: 0;
}

.thumbnail > img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}
.img-wrap svg {
  height: 20px;
}

.item-cate-wrap {
  height: 18px;
  line-height: 20px;
  display: flex;
}

.txt-th {
  font-size: 1rem;
}
.pos-abs-center svg {
  font-size: 5rem;
  filter: drop-shadow(2px 2px 2px black);
  opacity: 0.9;
}

.thumbnail.no-img > img {
  opacity: 1.0;
}

.btn-pmpt svg {
  height: 100%;
  opacity: 0.6;
}

.cart-desc p {
  height: 33px;
  margin-bottom: 0;
}

.cart-area tbody tr:first-child > * {
  border-top: 1px solid gray;
}

.cart-area tfoot tr:last-child th {
  background-color: white;
  height: 64px;
}

.cart-area tfoot tr:last-child th {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
}

@media (max-width: 991px) {
  .icon-wrap {
    min-width: 80px;
    width: 45%;
  }
  .img-grid .w-25 .thumbnail {
    border-radius: 0;
  }

  .pmpt-icon-area {
    width: 100%;
  }

  .cart-area {
    width: calc(100% - 4px);
  }

  .cart-wrap svg {
    font-size: 4rem;
  }

  .badge.crcy {
    margin: auto 6px;
  }
}

@media (min-width: 992px) {
  .img-grid .w-25 .thumbnail {
    border-radius: 0;
  }
  .icon-wrap {
    min-width: 180px;
  }
  .left-inner {
    padding: 0 4px 0 0;
  }

  .cart-area {
    width: calc(100% + 4px);
  }

  .badge.crcy {
    margin: 11px 6px 10px;
  }

  .cart-wrap svg {
    font-size: 4rem;
  }
}
</style>
