import { pxNum2Str } from '@/util/comn_util'

let spldHorzOptn = {
  // type: 'slide',
  slideFocus: true,
  autoWidth: true,
  fixedHeight: '110px',
  breakpoints: {
    640: {
      fixedHeight: '100%'
    }
  },
  perPage: 12,
  perMove: 1,
  arrows: false,
  keyboard: 'global',
  rewind: false,
  updateOnMove: true,
  pagination: true,
  paginationDirection: 'ltr',
  direction: 'ltr',
  cover: false,
  isNavigation: true,
  paginationKeyboard: true
}
let current
let splideMain, splideThum
let grupId
let fullScrnElem
let isKywdShow = true
let currIndx = -1
let zoomistMap = {}
let imgMedmUrl = []
let citePreTxt = []
let lcalImgList = []

function tnsfImgListData(imgList) {
  lcalImgList = imgList
  currIndx = imgList.length - 1
}

function initZoomSplide(slpdMoveClbk) {
  if (!lcalImgList || lcalImgList.length < 1) {
    return
  }
  if (splideMain) {
    splideMain.destroy()
  }
  if (splideThum) {
    splideThum.destroy()
  }
  splideMain = new Splide('#main-splide', {
    type      : 'slide',
    rewind    : false,
    pagination: false,
    arrows    : true,
    drag: true
  })
  splideThum = new Splide('#thum-splide', spldHorzOptn)
  splideMain.sync(splideThum)
  splideMain.mount()
  splideThum.mount()

  splideMain.on('move', function() {
    console.log('splide move')
    onSplideMove(splideMain.index)
    if (current) {
      current.classList.remove('is-active')
    }
    // Splide#index returns the latest slide index:
    let thumbnail = thumbnails[splideMain.index]
    if (thumbnail) {
      thumbnail.classList.add('is-active')
      current = thumbnail
    }
  })

  splideMain.on('active', function(slide) {
    console.log('[ACTIVE] ', slide.index)
    const currImg = lcalImgList[slide.index]
    document.getElementById('wdth-hegt').innerHTML = "(" + currImg.wdth + 'x' + currImg.hegt + 'px,&nbsp;' + pxNum2Str(currImg.wdth * currImg.hegt) + ')'
    document.getElementById('slct-indx').innerText = (slide.index + 1)
    slpdMoveClbk(slide.index)
    addNewZoom(slide.index)
  })

  splideMain.on('inactive', function(slide) {
    console.log('[INACTIVE] ', slide.index)
    removePrevZoom(slide.index)
  })

  // Collects LI elements:
  let thumbnails = document.getElementsByClassName('thumbnail')
  for (let i = 0; i < thumbnails.length; i++) {
    initThumbnail(thumbnails[i], i)
  }

  // The function to initialize each thumbnail.
  function initThumbnail(thumbnail, index) {
    thumbnail.addEventListener('click', function() {
      // move the carousel
      console.log('thumbnail click', index)
      splideMain.go(index)
    })
  }

  if (!fullScrnElem) {
    fullScrnElem = document.getElementById('img-ctnt-wrap')
  }

  preventPinchZoom()
  splideMain.go(lcalImgList.length - 1)
  onSplideMove(lcalImgList.length - 1)
  addNewZoom(lcalImgList.length - 1)
}

function preventPinchZoom() {
  document.addEventListener('touchmove', __blockPinchZoom, { passive: false })
}

function releasePinchZoom() {
  document.removeEventListener('touchmove', __blockPinchZoom, { passive: false })
}

function __blockPinchZoom(e) {
  if (e.touches.length > 1) {
    e.preventDefault() // preventDefault를 사용하려면 passive를 false로 해줘야합니다.
  }
}

function removePrevZoom(idx) {
  if (zoomistMap[`${idx}`]) {
    zoomistMap[`${idx}`].reset()
    zoomistMap[`${idx}`].destroy(true)
    zoomistMap[`${idx}`] = null
  }
}

function addNewZoom(idx) {
  const inxStr = (idx + 1) < 10 ? `0${(idx + 1)}` : `${(idx + 1)}`
  zoomistMap[`${idx}`] = new Zoomist(`#main-splide-slide${inxStr}`, {
    maxScale: 4,
    slider: false,
    zoomer: false,
    bounds: true,
    draggable: true
  })
}

function toglKywdShow() {
  isKywdShow = !isKywdShow
  if (isKywdShow) {
    document.getElementById('kywd-wrap-tr').classList.remove('d-none')
  } else {
    document.getElementById('kywd-wrap-tr').classList.add('d-none')
  }
}

function onFullScrnChng() {
  try {
    if (document.fullscreenElement) {
      fullScrnElem.classList.add('fullscreen')
    } else {
      console.log('Leaving fullscreen mode.')
      fullScrnElem.classList.remove('fullscreen')
    }
  } catch (e) {
    console.error('[FULLSCRN] e', e.message)
  }
}

function setFullScrn(setFull) {
  console.log('[IMGDETLPANE] set full scrn to', setFull)
  // 내장 객체임
  if (document.fullscreenElement) {
    // exitFullscreen is only available on the Document object.
    document.getElementById('btn-minimize').classList.add('d-none')
    document.exitFullscreen()
  } else {
    document.getElementById('btn-minimize').classList.remove('d-none')
    fullScrnElem.requestFullscreen()
  }
}

function onSplideMove(indx) {
  if (currIndx === indx) {
    return
  }
  console.log(`[SPLIDE] moving from ${currIndx} to ${indx}`, JSON.stringify(current))
  // currIndx = indx
}

function getCurrImg() {
  console.log('lcalImgList', JSON.stringify(lcalImgList))
  return lcalImgList[currIndx]
}

function onShre() {
  // const imgPath = model.d ? "?d=" + model.d : location.search ? location.search : ""
  let path = location.href
  console.log('path', path)
  const toast = document.getElementById("tost-clipbord")
  const msg = '<i class="fa-regular fa-copy me-2"></i>The content URL to share<br/>' +
    'has been copied<br />to the clipboard'
  document.getElementById("tost-msg").innerHTML = msg
  window.navigator.clipboard.writeText(path)
    .then(() => {
      toast.classList.add("show")
      setTimeout(function() {
        toast.classList.remove("show")
      }, 3000)
    })
}

function citeTextChng(idx) {
  document.getElementById("cite-pre-txt").innerHTML = citePreTxt[idx]
  let html = document.getElementById("quot-wrap").innerHTML.toString()
  document.getElementById("cite-html").innerText = html
}

function onCiteCtnt() {
  console.log('onCiteContent', imgMedmUrl[currIndx])
  document.getElementById("url-link").setAttribute("href", location.href)
  document.getElementById("img-to-quot").setAttribute("src", imgMedmUrl[currIndx])
  document.getElementById("cite-mdal").classList.remove("d-none")
}

function closCite() {
  document.getElementById("cite-mdal").classList.add("d-none")
}

function onCiteCopyClck() {
  let html = document.getElementById("quot-wrap").innerHTML.toString()
  console.log('html:', html)
  const toast = document.getElementById("tost-clipbord")
  const msg = '<i class="fa-regular fa-copy me-2"></i>The content HTML code<br/>' +
    'has been copied<br />to the clipboard'
  document.getElementById("tost-msg").innerHTML = msg
  window.navigator.clipboard.writeText(html)
    .then(() => {
      toast.classList.add("show")
      setTimeout(function() {
        toast.classList.remove("show")
      }, 3000)
    })
}

function isoDateToShortYear4(isoDateStr) {
  if (!isoDateStr) {
    return '-'
  }
  const userLang = navigator.language || navigator.userLanguage
  const theTimeInUtc = new Date(isoDateStr)
  const theTimeInHour = Math.ceil(theTimeInUtc.getTime() / 3600 / 1000)
  const nowInHour = Math.ceil(new Date() / 3600 / 1000)
  const hourDiff = nowInHour - theTimeInHour
  const isToday = hourDiff < 24
  const offset = theTimeInUtc.getTimezoneOffset() // 분단위
  const localTime = new Date(theTimeInUtc.getTime() - offset * 60 * 1000)
  const options = isToday
    ? {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }
    : {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  return new Intl.DateTimeFormat(userLang, options).format(localTime)
}

function onCnfmCnclClck() {
  document.getElementById("alert-diag").classList.add("d-none")
}

function goToSignIn() {
  location.href = "/view/sign/in?r=" + encodeURIComponent(location.pathname)
}

function onLike() {

  const tf = sessionStorage.getItem("tf")
  const isSignedIn = tf && tf.length > 9

  if (!isSignedIn) {

    document.getElementById("alert-diag").classList.remove("d-none")

  } else if (grupId) {

    fetch(location.origin + "/api/v1/prvt/pmpt/like", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "tf": tf,
          "tp": localStorage.getItem("tp")
        },
        body: JSON.stringify({
          grupId: grupId, ctntType: "image"
        })
      }).then((res) => {
        const data = res.data
        if (data.code === 200) {
          const toast = document.getElementById("tost-clipbord")
          let msg = ""
          if (data.rslt === true) {
            msg = '<i class="fa-solid fa-thumbs-up me-2 text-primary"></i> Added to like!'
          } else {
            msg = '<i class="fa-regular fa-trash-xmark me-2 text-danger"></i> Removed from like!'
          }
          document.getElementById("tost-msg").innerHTML = msg
          toast.classList.add("show")
          setTimeout(function() {
            toast.classList.remove("show")
          }, 3000)
        }
      })
      .catch((e) => {
        console.error("e", JSON.stringify(e))
      })
      .finally(() => {})
  }
}

export { initZoomSplide, tnsfImgListData, getCurrImg, onSplideMove, addNewZoom, preventPinchZoom }