<template>
  <nav class="navbar navbar-expand-lg bg-white shadow-smd1"
    :style="'top: ' + (cnst.navBarY ?? INIT_SRCH_Y_POS) + 'px;'">
    <div class="container-fluid nav-container gx-0">
      <!-- service logo start -->
      <a href="/" class="navbar-brand">
        <img class="nav-logo" src="/aset/img/icon/logo-generated-navbar.webp" />
      </a>
      <!-- service logo end -->
      <ul class="mobl-icon h-100 list-group-horizontal d-flex d-block d-lg-none">
        <!-- [mobile] my notice menu -->
        <li class="nav-item dropdown icon icon-bell position-relative">
          <div class="dropdown-toggle" v-wave
            id="navbarDropdownMobl" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa-sharp fa-regular fa-bell-ring"></i>
            <div v-if="user.isSignedIn && user.getUnReadNotiCnt > 0" class="bdge-cnt">
              <span class="badge red">{{ user.getUnReadNotiCnt }}</span>
            </div>
          </div>
          <drop-down-noti v-show="user.isSignedIn" labl="navbarDropdownMobl" :list="user.getAncmList" />
        </li>
        <!-- [mobile] my notice menu -->

        <!-- [mobile] my cart menu -->
        <li class="nav-item icon icon-bskt">
          <a href="/view/user/dashboard#cart" class="d-flex" role="button" v-wave>
            <i class="fa-regular fa-cart-shopping-fast"></i>
            <div v-if="user.isSignedIn && user.getCartCnt > 0" class="pos-abs-top-right">
              <span class="badge red">{{ user.getCartCnt }}</span>
            </div>
          </a>
        </li>
        <!-- [mobile] my cart menu -->
      </ul>

      <!-- mobile menu toggle(hamburger) button start -->
      <!-- ref. https://mdbootstrap.com/docs/b4/jquery/navigation/hamburger-menu/ -->
      <!-- Collapse button -->
      <button
        class="navbar-toggler mobile-toggle"
        data-mdb-toggle="collapse"
        data-mdb-target="#navbarToggleExternalContent"
        aria-controls="navbarToggleExternalContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <div class="animated-icon1"><span></span><span></span><span></span></div>
      </button>
      <!-- mobile menu toggle(hamburger) button end -->

      <div id="navbarToggleExternalContent" class="collapse navbar-collapse menu-items d-flex">

        <div class="right-menu-wrap w-100 d-flex p-justify-content-between">
          <!-- menu list start -->
          <ul class="navbar-nav left-nav nav-pills nav-fill">
            <!-- bootstrap class - invisible -->
            <li class="nav-item">
              <a href="/view/stock/images" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text ellipsis d-flex">AI Stock Images</span>
                  <span class="m-menu-icon d-block d-lg-none">
                    <i class="fa-regular fa-images fs-4 ic-pict m-0"></i>
                  </span>
                </div>
              </a>
            </li>

            <!-- invisible -->
            <li class="nav-item">
              <a href="/view/image/generator" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text d-flex ellipsis">Free Image Generator</span>
                  <span class="m-menu-icon d-block d-lg-none">
                    <i class="fa-regular fa-wind-turbine fs-4 m-0"></i>
                  </span>
                </div>
              </a>
            </li>
            <!-- invisible -->

            <!-- invisible -->
            <li v-if="mode === 'development' || mode === 'staging' || host === 'http://localhost'" class="nav-item">
              <a href="/view/free/images" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text d-flex ellipsis">Free Content</span>
                  <span class="m-menu-icon d-block d-lg-none">
                    <i class="fa-regular fa-hands-holding-heart fs-4 m-0"></i>
                  </span>
                </div>
              </a>
            </li>
            <!-- invisible -->

            <li class="nav-item">
              <a href="/view/policy/pricing" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text d-flex ellipsis">Pricing</span>
                  <span class="m-menu-icon d-block d-lg-none">
                    <i class="fa-solid fa-barcode-read fs-4 m-0"></i>
                  </span>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a href="/view/policy/faq" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text d-flex ellipsis">FAQ</span>
                  <span class="m-menu-icon faq d-block d-lg-none">
                    <i class="fa-regular fa-comment-question fs-4 m-0"></i>
                  </span>
                </div>
              </a>
            </li>

            <!--
            <li class="nav-item">
              <router-link v-wave class="nav-link ellipsis"
                to="/view/announcement/rankings">
                <div class="nav-link-chld">
                  <span>Rankings</span>
                  <span class="m-menu-icon d-block d-lg-none">
                    <i class="fa-sharp fa-regular fa-ranking-star fs-4 m-0"></i>
                  </span>
                </div>
              </router-link>
            </li>
            -->

            <li class='nav-item'>
              <a href="/view/announcement/events" class="nav-link" v-wave>
                <div class="nav-link-chld">
                  <span class="p-menu-text d-flex ellipsis">Events</span>
                  <span class="m-menu-icon evnt d-block d-lg-none">
                    <i class="fa-sharp fa-regular fa-ranking-star fs-4 ms-2 mt-2"></i>
                  </span>
                </div>
              </a>
            </li>

            <!-- invisible -->
            <li
              v-if="mode === 'development' || mode === 'staging'"
              class="nav-item shadow-smd3 d-block d-lg-none d-xxl-block"
              :class="mode === 'development' ? 'dev-mode' : 'beta-mode'">
              <div v-if="mode === 'development'">DEVELOPER MODE</div>
              <div v-if="mode === 'staging'">TEST MODE</div>
            </li>
            <!-- invisible -->

          </ul>
          <!-- menu list end -->

          <!-- right nav -->
          <ul class="navbar-nav right-nav nav-pills d-inline-flex justify-content-end">
            <!-- 'MY' menu button -->
            <li class="nav-item my">
              <!-- class="nav-link nav-link-chld my-wrap" -->
              <a href="/view/user/dashboard" class="nav-link" v-wave @click="goToMyPage">
                <div class="nav-link-chld">
                  <span class="text">My</span>
                  <span>
                    <i class="d-block d-lg-none fa-regular fa-head-side-brain fs-4 ms-2 mt-2"></i>
                  </span>
                </div>
              </a>
            </li>
            <!-- 'MY' menu button -->

            <!-- [PC] my notice menu -->
            <li class="nav-item nav-link icon d-none d-xxl-block icon-bell dropdown">
              <div class="dropdown-toggle"
                   id="navbarDropdownPC" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-sharp fa-regular fa-bell-ring"></i>
                <div v-if="user.isSignedIn && user.getUnReadNotiCnt > 0" class="bdge-cnt">
                  <span class="badge red">{{ user.getUnReadNotiCnt }}</span>
                </div>
              </div>
              <drop-down-noti v-show="user.isSignedIn" labl="navbarDropdownPC" :list="user.getAncmList" />
            </li>
            <!-- [PC] my notice menu -->

            <!-- [PC] my cart menu -->
            <li class="nav-item nav-link icon d-none d-xxl-block icon-bskt">
              <a href="/view/user/dashboard#cart" class="d-flex" role="button" v-wave>
                <i class="fa-regular fa-cart-shopping-fast"></i>
                <div v-if="user.isSignedIn && user.getCartCnt > 0" class="pos-abs-top-right">
                  <span class="badge red">{{ user.getCartCnt }}</span>
                </div>
              </a>
            </li>
            <!-- [PC] my cart menu -->

            <!-- sign in button -->
            <li v-if="!user.isSignedIn" class="nav-item btn-sign btn-sgin">

              <a :href="getSignInUrl()" class="nav-link btn btn-outline-light btn-signin shadow-md" v-wave @click="goToMyPage">
                <div class="d-flex justify-content-center margin-auto">
                  <span class="icon  d-block d-lg-none me-2"><!-- me-2 mt-1 -->
                    <i class="fa-solid fa-person-running-fast"></i>
                    </span>
                  <div class="text">Sign In</div>
                </div>
              </a>
            </li>
            <!-- sign in button -->
            <!-- sign up button -->
            <li v-if="!user.isSignedIn" class="nav-item btn-sign btn-sgup">
              <a :href="getSignUpUrl()" class="nav-link btn btn-outline-light btn-signin shadow-md" v-wave @click="goToMyPage">
                <div class="d-flex justify-content-center margin-auto">
                  <span class="icon me-2 d-block d-lg-none"><!-- me-2 mt-1 -->
                    <i class="fa-regular fa-user-plus"></i>
                  </span>
                  <div class="text">Sign Up</div>
                </div>
              </a>
            </li>
            <!-- sign up button -->

            <li v-if="user.isSignedIn"
              class="nav-item prof-pic-area position-relative m-bt-solid text-end">
              <!-- my(signed in use)r profile area -->
              <crtr-info-full-horz type="jwt" @click="showMyInfo" class="fs-4" />
              <!-- my(signed in use)r profile area -->
            </li>
          </ul>
          <!-- right nav -->

          <div class="d-block d-lg-none w-100 btn-pos-abs-btm m-polc-area">
            <div class="text-end txt-comp">
              <p class="fw-normal">
                ⓒ 2024 Generated, Inc.<br class="d-block d-md-none"/>651 N Broad St, Middletown, DE, USA
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { onMounted, reactive, watch } from "vue"
import router from "@/rout"
import { user_stor } from "@/stor/user_stor"
import CrtrInfoFullHorz from '@/view/cpnt/CrtrInfoFullHorz.vue'
import DropDownNoti from '@/view/cpnt/DropDownNoti.vue'
import { cnst_stor } from '@/stor/cnst_stor'

const cnst = cnst_stor()
const host = import.meta.env.VITE_APP_HOST
const user = user_stor()
const mode = import.meta.env.VITE_APP_ENV
const model = reactive({
  userData: null,
  mode: "",
})

watch(
  () => user.getAncmList,
  (param) => {
    model.ancmList = param
  }
)

watch(
  () => user.getUnReadNotiCnt,
  (param) => {
    model.ancmCnt = param
  }
)

onMounted(() => {
  // 표시언어는 브라우저 언어설정(o)을 따름, 접속국가(x)
  document.querySelector(".nav-item")?.addEventListener("click", closeMobileMenu)
  document.querySelector(".mobile-close")?.addEventListener("click", closeMobileMenu)
  document.querySelector(".mobile-toggle")?.addEventListener("click", toggleMobileMenu)
  const url = location.href
  document.querySelectorAll('.nav-link').forEach((it) => {
    if (url.indexOf(it.getAttribute('href'))>-1) {
      it.classList.add('router-link-active')
    } else {
      it.classList.remove('router-link-active')
    }
  })
})

function goToMyPage() {
  const destUrl = "/view/user/dashboard"
  if (user.isSignedIn) {
    router.push(destUrl)
  } else {
    const r = encodeURIComponent(destUrl)
    router.push(`/view/sign/in?r=${r}`)
  }
  closeMobileMenu()
}

function showMyInfo() {
  user.showMyInfo(true)
  closeMobileMenu()
}

function getSignInUrl() {
  try {
    const q =
      location.search && location.search.startsWith("?r=")
        ? location.search
        : location.pathname === "/" || location.pathname.indexOf("/sign/") > -1
        ? ""
        : "?r=" + encodeURIComponent(location.pathname)
    return `/view/sign/in${q}`
  } catch (e) {
    console.error(`[SGIN] e`, e.message)
    return '/view/sign/in'
  }
}

function getSignUpUrl() {
  try {
    const q =
      location.search && location.search.startsWith("?r=")
        ? location.search
        : location.pathname === "/" || location.pathname.indexOf("/sign/") > -1
        ? ""
        : "?r=" + encodeURIComponent(location.pathname)
    return `/view/sign/up${q}`
  } catch (e) {
    console.error(`[SGUP] e`, e.message)
    return '/view/sign/up'
  }
}

function toggleMobileMenu() {
  const sideMenu = document.getElementById("navbarToggleExternalContent")
  if (sideMenu) {
    if (sideMenu.classList.contains("show")) {
      document.querySelector(".animated-icon1")?.classList.remove("open")
      sideMenu.classList.remove("show")
    } else {
      document.querySelector(".animated-icon1")?.classList.add("open")
      sideMenu.classList.add("show")
    }
  }
}

function closeMobileMenu() {
  document.querySelector(".animated-icon1")?.classList.remove("open")
  document.getElementById("navbarToggleExternalContent")?.classList.remove("show")
}
</script>

<style scoped>
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.hof > a {
  padding-left: 12px;
  padding-right: 12px;
}

.nav-user-prof-img svg:not(:host).svg-inline--fa,
.nav-user-prof-img svg:not(:root).svg-inline--fa {
  overflow: hidden;
  height: 0.9em;
  vertical-align: 0.1em;
  border-radius: 40%;
  opacity: 0.7;
  color: #585858;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  border: 0;
  outline: none;
  border-radius: 0;
  box-shadow: none !important;
}

.collapse.navbar-collapse.menu-items ul.right-nav, ul.mobl-icon {
  overflow: unset;
}

.m-polc-area {
  width: 80%;
  bottom: 60px;
  padding: 1rem 1rem;
  font-size: 0.8rem;
}

.m-polc-area a {
  text-decoration: none;
  color: #585858;
}

.m-polc-area a + a {
  margin-left: 6rem;
}

.nav-item svg {
  color: #585858;
}

.left-nav .nav-item .nav-link {
  padding: 8px 10px;
  margin-left: 0;
  margin-right: 0;
}

.left-nav .nav-item .nav-link > * {
  height: 22px;
  line-height: 22px;
  font-weight: 600;
}

.left-nav .nav-item .router-link-active svg,
.right-nav .nav-item.my .router-link-active svg {
  color: white;
}

.left-nav {
  overflow-y: hidden;
}

.left-nav .nav-item .nav-link {
  line-height: 23px;
}

.nav-item .badge.red {
  min-width: 17px;
  height: 18px;
  font-size: 10px;
  margin-right: 0;
  color: white;
  border: 0;
  border-radius: 12px;
  font-weight: bold;
  background-color: rgb(228, 76, 6);
  line-height: 1.6;
  padding: 1px 3px 1px 3px;
}

.navbar-nav.right-nav .nav-item.my {
  padding: 5px 0;
}
.navbar-nav.right-nav .nav-item.my + .nav-item {
  margin-left: 0;
}

.navbar-nav.right-nav .nav-item.my > .nav-link > * {
  line-height: 38px;
}

.nav-link-chld {
  display: flex;
  justify-content: center;
}

.nav-link-chld > * {
  font-weight: 600;
  letter-spacing: 0;
}

.left-nav .nav-link-chld span, .right-nav .router-link .text
  , .right-nav .nav-link .text{
  font-family: "Archivo Narrow", serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1;
}

.left-nav .router-link-active .nav-link-chld span {
  font-weight: 600;
}

.right-nav .router-link .text {

}

.navbar-nav.right-nav .nav-item.my > .nav-link .nav-link-chld {
  line-height: 22px;
}

.bdge-cnt {
  position: absolute;
  right: 0;
  top: 6px;
}

.dropdown-toggle::after {
  display: none;
}

.nav-container .nav-item.icon {
  height: 100%;
  line-height: 23px;
  padding: 13px 5px;
  position: relative;
  font-size: 1.5rem;
}

.nav-container .nav-item.icon svg {
  height: 23px;
}

.nav-container .nav-item.icon.icon-bell {
  padding: 12px 5px 14px !important;
}

.mobl-icon .nav-item.icon-bell > .dropdown-toggle {
  margin-bottom: 1px;
}

.dropdown .dropdown-menu {
  width: 344px;
  padding: 0;
}

.icon-bell .dropdown-menu + .dropdown-menu {
  border-top: 1px dashed gray;
}

@media (max-width: 991px) {

  .dropdown .dropdown-menu {
    left: initial;
    right: -90px;
    top: 46px;
  }

  .nav-link-chld {
    justify-content: start;
    height: 38px !important;
    line-height: 38px !important;
  }

  .navbar-nav.right-nav .nav-item.my {
    padding: 5px 8px;
    box-shadow: rgba(60, 64, 67, 0.3) 0 3px 5px;
    border-top: none;
    border-bottom: 1px solid gray;
  }
  .navbar-nav.right-nav .nav-item.my .nav-link {
    padding: 1px 19px;
  }
  .nav-pills > .nav-item > a {
    padding: 9px 12px;
  }
  .nav-pills > .nav-item > a svg {
    font-size: 18px;
  }
  .nav-item.cart .badge.red {
    top: 5px;
  }
  .navbar-toggle:focus {
    box-shadow: none !important;
  }
  .m-menu-icon {
    margin: 8px 0 0 8px;
    line-height: 1;
  }

  .m-menu-icon.faq {
    margin: 10px 0 0 10px;
  }

  .m-menu-icon.evnt {
    margin: 1px 0 0 1px;
  }

  .left-nav .nav-item .nav-link {
    line-height: 38px;
  }

  .left-nav .nav-item + .nav-item {
    border-top: 1px dashed gray;
  }

  .left-nav .nav-item + .nav-item {
    border-top: 1px dashed gray;
  }

  .right-nav .nav-item:not(:last-child) {
    border-top: 1px solid gray;
  }

  .right-nav .nav-link {
    height: 40px !important;
  }

  .right-nav .my .nav-link-chld {
    line-height: 34px !important;
  }

  .mobl-icon > .nav-item.cart > a {
    padding-top: 3px;
  }

  .mobl-icon {
    position: absolute;
    top: 0;
    right: 56px;
    width: fit-content;
    list-style-type: none;
  }

  .mobl-icon > li {
    position: relative;
  }

  .mobl-icon > li + li {
    margin-left: 0.4rem;
  }

  .mobl-icon > li svg {
    height: 1.5rem;
  }

  .my-wrap {
    height: 39px;
    line-height: 39px !important;
  }

  .ic-pict {
    margin-top: 0.6rem;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 1px 19px;
  }

  .collapse.navbar-collapse.menu-items {
    padding-left: 50px;
    height: calc(100vh + 24px);
  }

  .collapse.navbar-collapse.menu-items > * {
    background-image: url("@/assets/img/bg/bg-leather-white-rect-small.webp");
    flex-direction: column;
  }

  .collapse.navbar-collapse.menu-items .left-nav {
    background-color: white;
    border-bottom: 1px dashed gray !important;
  }

  /* Icon 1 */
  .navbar-toggler {
    height: 50px;
    padding: 13px 12px 12px 12px;
    border: 0;
    border-radius: 0;
  }

  .navbar-toggler:focus {
    box-shadow: 0 0 0 1px; /* var(--bs-navbar-toggler-focus-width); */
  }

  .dev-mode {
    position: absolute;
    margin-left: 0;
    top: 60%;
    left: 50%;
    width: 300px;
    transform: translate(-50%, -50%);
  }

  .dev-mode > * {
    font-size: 0.8rem;
    text-align: center;
  }

  .navbar-nav.right-nav .nav-item.prof-pic-area {
    text-align: end;
    padding: 8px 0;
  }

  .collapse.navbar-collapse .nav-item:not(.dev-mode) {
    list-style: none;
    font-size: 1.2rem;
    font-weight: bold;
    height: 50px;
    padding-bottom: 4px;
  }

  .collapse.navbar-collapse .nav-link {
    margin-top: 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 1px 19px;
  }

  .navbar-expand-lg .navbar-nav .nav-link.btn {
    padding: 1px 0;
  }

  .navbar-nav.right-nav .nav-item + .nav-item {
    margin-left: 0;
  }

  .btn-signin,
  .btn-signup {
    width: 100%;
    height: 40px;
    line-height: 38px;
    font-size: 1.2rem;
  }
  .btn-sign .text {
    line-height: 26px;
  }
  .nav-item.btn-sgin {
    padding-bottom: 4px !important;
  }

  .nav-item.btn-sgup {
    padding-top: 4px !important;
  }
  .nav-item.btn-sign.btn-sgin {
    margin-top: 1rem;
    border-top: none !important;
  }
  .nav-item.btn-sign > .btn {
    background-color: rgba(0,0,0,0.3);
    color: white;
    border-color: white;
  }
  .nav-item.btn-sign svg {
    color: white;
  }
  .collapse.navbar-collapse.menu-items {
    visibility: hidden !important;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    z-index: 2;
    height: 100vh;
    width: 100%;
    top: 50px;
  }

  .collapse.navbar-collapse.menu-items.show {
    visibility: visible !important;
  }

  .navbar .menu-items .right-menu-wrap {
    transform: translateX(100%);
    top: 0;
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    text-align: center;
  }

  .navbar .menu-items.show .right-menu-wrap {
    position: absolute;
    transform: translateX(0);
    width: calc(100% - 50px) !important;
  }

  .navbar-collapse.menu-items > .navbar-nav > .nav-item {
    border-bottom: 1px dashed #aaa;
  }

  .container-fluid.nav-container {
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
  }

  .left-nav,
  .right-nav {
    width: 100%;
  }

  .navbar-nav .nav-item + .nav-item {
    margin-left: 0;
    padding: 0 12px;
  }

  .navbar-expand-lg .navbar-nav .nav-link.btn {
    padding-left: 0;
    padding-right: 0;
  }

  .right-nav > .nav-item {
    height: 51px;
  }

  .right-nav > .nav-item > a.btn-signin,
  .right-nav > .nav-item > .btn-signup {
    line-height: 28px !important;
  }

  .right-nav .nav-link > * {
    line-height: 28px;
  }

  .navbar-nav.left-nav .nav-item {
    padding: 5px 8px;
  }

  .navbar-nav.right-nav .nav-item.my {
    background-color: white;
  }

  .navbar-nav.right-nav .nav-link-chld .text {
    padding: 8px 0 8px;
  }

  .p-menu-text {
    margin-top: 9px;
  }

  .right-menu-wrap {
    width: 100%;
    height: 100%;
    border-left: 1px solid gray;
    position: relative;
  }
}

@media (min-width: 992px) {

  .dev-mode {
    max-width: 150px;
  }

  .navbar-nav.right-nav .nav-item.icon .dropdown-toggle {
    position: relative;
  }

  .dropdown .dropdown-menu {
    left: initial;
    right: -5rem;
  }

  .m-menu-icon {
    margin-left: 8px;
    line-height: 1;
  }
  .p-menu-text {
    padding-top: 1px;
  }
  .nav-item.btn-sign {
    margin: 6px 0;
    height: 36px !important;
  }
  .nav-item.btn-sign .btn {
    height: 36px;
    line-height: 1;
    padding: 6px 9px 10px;
  }
  .nav-item.btn-sign.btn-sgin {
    border-right: 1px solid gray;
  }
  .btn-sgin > a {
    border-right: 1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn-sgup > a {
    border-left: 1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .collapse.navbar-collapse.menu-items ul {
    overflow: hidden;
  }

  .navbar-nav.right-nav {
    padding-right: 0.5rem;
  }

  .navbar-nav.right-nav .nav-item, .navbar-nav.right-nav .nav-item.my > * {
    height: 100%;
  }

  .navbar-nav.right-nav .nav-item.my > * {
    padding: 10px 8px 8px;
  }

  .navbar-nav.right-nav .nav-item.icon {
    position: relative;
    width: 46px;
    text-align: center;
  }
  .navbar-nav.right-nav .nav-item.prof-pic-area {
    padding: 7px 0;
  }
  .bdge-cnt {
    position: absolute;
    right: 0;
    top: -5px;
  }

  .nav-item.btn-sgin {
    margin-left: 0.5rem;
  }

  .btn-signin.router-link-active,
  .btn-signup.router-link-active {
    --bs-btn-color: #fff;
    --bs-btn-bg: rgb(0, 169, 204);
    --bs-btn-border-color: rgb(0, 169, 204); /* #75b798; */
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: rgba(0, 169, 204, 0.8);
    --bs-btn-hover-border-color: #5e927a;
    --bs-btn-focus-shadow-rgb: 138, 194, 167;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: rgb(0, 169, 204); /* #5e927a; */
    --bs-btn-active-border-color: #588972;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #aaaaaa;
    --bs-btn-disabled-border-color: 0;
    color: white !important;
    font-weight: 500;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.5) !important;
  }
}

@media (max-width: 1280px) {
  .nav-item.my, .titl-pmpt-4 {
    display: none;
  }
}
</style>
