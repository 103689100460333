<template>
  <!-- modal bg (img detail) -->
  <div class="modal" tabindex="-1">
    <!-- modal dialog-->
    <div class="modal-dialog modal-xl bg-dark br-2px">
      <!-- modal content-->
      <div id="modal-content" class="modal-content">
        <!-- modal body-->
        <div id="img-modal-body" class="modal-body">

          <img-work-detl-pane
            ref="detlPane"
            v-if="model.step === 1 && model.data"
            :data="model.data"
            :mode="model.mode"
            @showRprtMdal="showRprtMdal"
            @setCurrItemId="setCurrItemId"
            @onQuot="onQuot"
            @onPcseClck="onPcseClck"
          />

          <img-work-pcse-stp1-pane
            ref="pamtPane"
            v-if="model.step === 2 || model.step === 3"
            :data="model.data"
            @onCartChng="onCartChng"
            :payData="model.payData"
            @onPaySuccess="onPamtSucc"
            @onAddCart="onAddCart"
            :step="model.step"
            :releaseUi="model.releaseUi"
            @onStep2NextClick="onStep2NextClick"
            @onPrev="onPrev"
            @closMdal="closMdal"
          />

        </div>

      </div>
      <!-- modal content -->

      <div v-if="model.brkn" class="pos-abs-center">
        <img src="@/assets/img/icon/ico-file-not-fund.png" />
      </div>
      <div v-if="model.noPermTeen" class="pos-abs-center">
        <img src="/aset/img/icon/icon-permission-teen.webp" class="icon-ctnt-grad"/>
        <p class="text-white text-center fw-light mt-1">Please sign-in to view the content</p>
      </div>

      <btn-close-top-right class="white" @onCloseClick="closMdal" />
      <spnr-no-block class="loading" v-if="model.loading" theme="absolute white" />

    </div>

    <rprt-modal
      v-if="model.showRprtMdal"
      @closeModal="closRprtMdal"
      :grupId="model.grup.grupId"
      :crtrId="model.grup.crtrId"
    />

  </div>
  <!-- modal bg (img detail) -->

  <quot-prvw-modal v-if="model.citeShow" :cite-info="model.citeInfo" @closeModal="model.citeShow=false"/>

</template>

<script setup>
import { onBeforeUnmount, onMounted, reactive, watch, ref } from "vue"
import axis_cstm from "@/util/axis_cstm"
import { user_stor } from "@/stor/user_stor"
import { ResCd, API_ROOT, ArtRgstStep } from "@/util/comn_cnst"
import BtnCloseTopRight from "@/view/btns/BtnCloseTopRight.vue"
import ImgWorkDetlPane from "@/view/pane/ImgWorkDetlPane.vue"
import ImgWorkPcseStp1Pane from "@/view/pane/ImgWorkPcseStep1Pane.vue"
import router from "@/rout"
import {
  blockRefresh,
  preventBgScroll,
  releaseBgScroll,
  unblockRefresh
} from "@/util/ui_evnt_util"
import SpnrNoBlock from "@/view/pgrsAlrt/SpnrNoBlock.vue"
import RprtModal from "@/view/modal/RprtModal.vue"
import { mdal_stor } from '@/stor/mdal_stor'
import QuotPrvwModal from '@/view/modal/QuotPrvwModal.vue'

const mdal = mdal_stor()
const emit = defineEmits(["closeModal"])
const prop = defineProps(["param"])
const user = user_stor()
const pamtPane = ref()
const detlPane = ref()
const model = reactive({
  loading: true,
  mode: null,
  d: null,
  isPay: false,
  grup: null,
  grupId: null,
  step: 1,
  brkn: false,
  noPermTeen: false,
  data: null,
  fromExternal: false,
  imgList: [],
  user: null,
  disabled: true,
  hideCnclBtn: true,
  payData: {},
  rgstStep: "",
  showRprtMdal: false,
  releaseUi: false,
  citeInfo: null,
  param: null
})

watch(
  () => model.step,
  (param) => {
    model.step = param
  }
)

watch(
  () => prop.param,
  (param) => {
    model.param = param
  }
)

onMounted(() => {

  blockRefresh()
  if (document.body.offsetWidth < 992) {
    preventBgScroll()
  }

  // let grupId = user.getPmptImgGrupId && user.getPmptImgGrupId.length > 0 ? user.getPmptImgGrupId[0] : null
  if (prop.param && prop.param.grupId && prop.param.grupId.length > 0) {
    model.param = prop.param
    // 1. 온사이트에서 prop으로 넘어오는 경우
    console.log('[IMG-DETL-POP] opened by App.vue param')
    model.grupId = model.param.grupId
    let qury = ''
    if (model.param.itemId) {
      model.itemId = model.param.itemId
      qury = '?d=' + model.param.itemId
    } else {
      model.itemId = null
    }
    if (model.param.rgstStep) {
      model.rgstStep = model.param.rgstStep
    } else {
      model.rgstStep = null
    }

    if (model.param.isPay===true) {
      model.isPay = true
      history.pushState({}, null, `/view/stock/images/${model.grupId}/pay${qury}`)
    } else {
      history.pushState({}, null, `/view/stock/images/${model.grupId}${qury}`)
    }

  } else {

    // 2. 타 사이트에서 브라우저 URL로 바로 넘어오는 경우
    console.log(`[IMG-DETL-POP] opened by direct URL access, external: ${model.fromExternal}, host: ${location.hostname}`)
    model.grupId = location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
    const params = new URL(location.href).searchParams
    model.d = params && params.get("d") ? params.get("d") : null
    model.rgstStep = ArtRgstStep.APPROVED

  }

  if (model.grupId) {
    loadImgDetl(model.grupId)
  }

})

onBeforeUnmount(() => {
  unblockRefresh()
  // window.removeEventListener('popstate', onBackPres)
  if (document.body.offsetWidth < 992) {
    releaseBgScroll()
  }
})

function closRprtMdal() {
  model.showRprtMdal = false
}

function showRprtMdal() {
  model.showRprtMdal = true
}

function setCurrItemId(itemId) {
  if (itemId) {
    model.d = itemId
    console.log("setCurrItemId", itemId)
  }
}

// Step2 -> Step3
// customize checkout page: https://docs.stripe.com/checkout/embedded/quickstart#customize-toggle
async function onStep2NextClick() {
  // 1) if member purchase
  if (user.isSignedIn) {
    // Member purchase
    await axis_cstm()
      .get(API_ROOT + "prvt/my/basc")
      .then((res) => {
        const data = res.data
        if (data.code === ResCd.SUCCESS) {
          model.payData["user"] = data.rslt
        }
        model.step = 3
      })
      .catch((err) => {
        console.error("err", err)
      })
      .finally(() => {})
  } else {
    model.step = 3
  }

}

function onQuot() {
  // pblcThumUrl (x) pblcWtmkUrl
  model.citeInfo = {
    crtrNm: model.grup.crtrNm,
    titl: model.grup.titl,
    date: model.grup.aprvDttm.substring(0, 4),
    host: location.host,
    imgUrl: detlPane.value.getThum600Url(),
    refUrl: location.href,
  }
  console.log('data', JSON.stringify(model.citeInfo))
  model.citeShow = true
}

function onAddCart() {
  if (!user.isSignedIn) {
    mdal.confirm(
      "You must be signed in to add to cart.<br/>Are you sure you want to sign in?",
      function () {
        const imgPath = model.d ? "?d=" + model.d : location.search ? location.search : ""
        router.push(
          "/view/sign/in?r=" + encodeURIComponent(`/view/stock/images/${model.grup.grupId}${imgPath}`)
        )
      },
      '<i class="fa-solid fa-check me-2"></i>Sign in'
    )
  } else {
    let totlPric = (model.payData.imgPricAmt * model.payData.imgCnt + model.payData.pmptPricAmt) / 100
    //Todo
    // ctry 추가
    const param = {
      grupId: model.grup.grupId,
      items: model.payData.items,
      imgIdList: model.payData.imgIdList,
      totlPric: totlPric,
      ctry: 'US',
      crcy: sessionStorage.getItem('crcy'),
      imgCnt: model.payData.imgCnt,
      pmptCnt: model.payData.pmptCnt
    }
    axis_cstm()
      .post(API_ROOT + "prvt/cart", param)
      .then((res) => {
        const data = res.data
        if (data.code === ResCd.REGISTERED) {
          user.updtCartCnt(1)
          mdal.toast('<i class="fa-solid fa-cart-shopping me-2 text-primary"></i> Added to cart!')
        }
      })
      .catch((err) => {
        console.error("err", err)
      })
      .finally(() => {})
  }
}

function onPcseClck() {
  model.step = 2
}

/**
 * Definition
 * @param param
 */
function onCartChng(param) {
  // 그룹ID, 타이틀, 이미지ID 리스트, 이미지가격ID/이름, 프롬프트가격ID/이름
  // , 할인ID, 할인률,
  if (param==null) {
    console.log('[modal] onCartChng paydata null')
    model.payData = null
  } else {
    console.log('[modal] onCartChng paydata', JSON.stringify(param))
    model.payData = param
  }
}

/**
 * e.currentTarget.history.state.back
 * e.target.history.state.back
 * @param e
 */
function onBackPres(e) {
  console.log('onBackPres', e)
  if (model.fromExternal) {
    router.push('/view/stock/images')
  } else {
    mdal.closImgDetlPopUp()
  }
}

function closMdal() {
  if (model.step === 3) {
    console.log('closMdal 2')
    mdal.confirm(
      "You're going through<br/>the checkout process.<br/>Are you sure you want to stop?",
      function() {
        mdal.closImgDetlPopUp()
        location.reload()
      },
      '<i class="fa-solid fa-check me-2"></i>Yes',
      null,
      '<i class="fa-solid fa-xmark-large me-2"></i>Cancel'
    )
  } else {
    console.log('closMdal 1')
    mdal.closImgDetlPopUp()
  }
}

function onPrev() {
  console.log("onPrev", model.step)
  model.step--
  if (model.step === 2) {
    model.releaseUi = true
  } else {
    model.step = 1
  }
}

function onPamtSucc(param) {
  // ref. https://developer.paddle.com/api-reference/notification-settings/create-notification-setting
  // Todo: 1) Send payment data to server or get by webhook(Notifications)
  // Todo: 2) then router.push
  console.log("[onPamtSucc]", JSON.stringify(param))
  mdal.confirm(
    "Payment has been completed.<br/>Would you like to go to the order list?",
    function () {
      // mdal.closImgDetlPopUp()
      // router.push("/view/user/dashboard")
      location.href = "/view/user/dashboard"
    },
    "Yes",
    closMdal
  )
}

function loadImgDetl(grupId) {

  const axios = axis_cstm()
  const tl = sessionStorage.getItem("tl")
  const c1 = sessionStorage.getItem("crcy")
  // console.log("tl", tl, "c1", c1)
  if (tl && tl.length === 2) {
    axios.defaults.headers["tl"] = tl
  }
  if (c1 && c1.length === 2) {
    axios.defaults.headers["c1"] = c1
  }

  let isPayPop = false
  let midlPath = "prvt"
  let lastPath = ''
  if (!model.rgstStep) {
    midlPath = "pblc"
  } else {
    // !important
    lastPath = (model.rgstStep===ArtRgstStep.REJECTED)?'/rejt'
      :(model.rgstStep===ArtRgstStep.WORKING_1)?'/wrkg'
        :(model.rgstStep===ArtRgstStep.REQUESTED)?'/wait':'/aprv'
    model.mode = lastPath.substring(1)
  }
  console.log('location.pathname', location.pathname)
  if (location.pathname.endsWith('/pay') || location.pathname.endsWith('/pay/')) {
    isPayPop = true
  }

  // console.log(`[PMPT_IMG_MODL] ${midlPath}/pmpt/imgs/${grupId}${lastPath}`)
  model.loading = true
  axios
    .get(API_ROOT + `${midlPath}/pmpt/imgs/${grupId}${lastPath}`)
    .then((res) => {
      //console.log('res', JSON.stringify(res))
      if (res.status===ResCd.BROKEN) {
        model.brkn = true
        return
      } else if (res.data.code===ResCd.NO_PERN_TEEN) {
        model.noPermTeen = true
        return
      }
      const data = res.data
      if (data.code === ResCd.SUCCESS) {
        if (!data.rslt) {
          mdal.confirm(
            "Failed to load data,<br/>would you like to try again?",
            function () {
              loadImgDetl(grupId)
            },
            '<i class="fa-solid fa-check me-2"></i>OK',
            closMdal
          )
          return
        } else if (data.rslt.hasPerm === false) {
          mdal.alert("You don't have permission<br/> to view the content.", closMdal)
          return
        }
        let idx = 0
        let imgIdx = 0
        if (data.rslt.imgList) {
          // console.log('imgs', JSON.stringify(data.rslt.imgList))
          if (model.d) {
            data.rslt.imgList.forEach((it) => {
              if (it.itemId === model.d) {
                imgIdx = idx
              }
              idx++
            })
          } else {
            imgIdx = 0
          }
        }
        model.data = data.rslt
        model.grup = data.rslt.grup
        model.imgList = data.rslt.imgList
        model.grup.index = imgIdx
        model.data.index = imgIdx
        console.log('isPayPop', isPayPop)
        if (isPayPop) {
          onPcseBtnClck()
        }
      }
    })
    .catch((err) => {
      console.error("err", err)
    })
    .finally(() => {
      model.loading = false
    })
}
</script>

<style scoped>
.modal-content {
  border: none;
  background-color: transparent;
}
.modal-body {
  overflow-x: hidden;
  background-color: rgb(33, 37, 41);
}
.img-page > svg {
  font-size: 1.7rem;
  margin-right: 0.5rem;
}

.img-page > div {
  padding-bottom: 0.5rem;
  line-height: 1.4rem;
}

.img-detail hr {
  color: #75b798;
  opacity: 1;
  border-width: 5px;
}

span:last-child {
  margin-right: 0;
}

.creator > svg {
  font-size: 18px;
  height: 18px;
  margin-right: 6px;
  color: #555;
}

.icon-ctnt-grad {
  width: 300px;
}

@media (max-width: 991px) {
  .br-2px {
    border-right-width: 2px;
  }
}

@media (min-width: 992px) {
  .modal-content {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .modal-body {
    overflow-y: hidden;
  }
  .modal-content {
    overflow: hidden;
  }
}
</style>
