import axios, { type AxiosInstance, type AxiosResponse } from 'axios'
import { ResCd, HEDR_APP_JSON, BrwsStrgKey } from '@/util/comn_cnst'
import { getToknExprTimeInMinSec, rfshToknSync } from '@/util/auth_func'
import { user_stor } from '@/stor/user_stor'

const laxios: AxiosInstance = axios.create()
laxios.defaults.headers['Content-Type'] = HEDR_APP_JSON
laxios.defaults.headers['Accept'] = 'application/*'
laxios.defaults.headers['Access-Control-Allow-Credentials'] = 'true'
laxios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
laxios.defaults.withCredentials = true
let toknRfsh = false

// https://stackoverflow.com/questions/76160628/axios-interceptor-on-request-does-not-wait-for-await-function-calls-to-be-finish
laxios.interceptors.request.use(
  async (config) => {
    if (user_stor().isSignedIn) {
      const minLeft = getToknExprTimeInMinSec()[0]
      if (minLeft<0) {
        user_stor().clerUserDataAndGoMain(undefined, true)
      } else if (minLeft < 25 && !toknRfsh) {
        toknRfsh = true
        const res = await rfshToknSync()
        console.log(`[IntrCeptRqst] ${res.status}`)
        if (res.status===401) {
          user_stor().clerUserDataAndGoMain(undefined, true)
        } else if (res.data.code === ResCd.SUCCESS) {
          // rslt => accsTokn, thumUrl, dispName, cartCnt, unReadNotiCnt, notiList
          const tokn = res.data.rslt.accsTokn
          if (tokn) {
            user_stor().setTokn(tokn)
            user_stor().setCartCnt(res.data.rslt.cartCnt)
            const notiInfo = {
              notiList: res.data.rslt.notiList,
              unReadNotiCnt: res.data.rslt.unReadNotiCnt
            }
            user_stor().setAncmInfo(notiInfo)
            sessionStorage.setItem(BrwsStrgKey.USER_PROF_IMG, res.data.rslt.thumUrl)
            sessionStorage.setItem(BrwsStrgKey.USER_NAME, res.data.rslt.dispName)
            console.log(`[AXIS-INTRCPT] got new one ${getToknExprTimeInMinSec()[0]}m left`)
          }
        } else if (res.data.code === ResCd.TOKN_EXPD) {
          user_stor().clerUserDataAndGoMain('Your session has expired.')
        }
        toknRfsh = false
      }
    }
    return config
  }, (e) => {
    // 2. 요청 에러가 있는 작업 처리
    console.warn(`[RQST-INTCPT] e ${e.message}`)
    return Promise.reject(e);
  })

// https://velog.io/@xmun74/axios-interceptors-%EC%82%AC%EC%9A%A9%ED%95%98%EA%B8%B0
laxios.interceptors.response.use(
  (response) => {

    if (response.headers[BrwsStrgKey.USER_TIMEZONE]) {
      if (
        !localStorage.getItem(BrwsStrgKey.USER_TIMEZONE) ||
        localStorage.getItem(BrwsStrgKey.USER_TIMEZONE) === null ||
        localStorage.getItem(BrwsStrgKey.USER_TIMEZONE) === 'null' ||
        localStorage.getItem(BrwsStrgKey.USER_TIMEZONE) === 'undefined'
      ) {
        const tz = response.headers[BrwsStrgKey.USER_TIMEZONE]
        if (tz && tz.length > 4 && tz !== 'undefined') {
          localStorage.setItem(BrwsStrgKey.USER_TIMEZONE, tz)
        }
      }
    }
    return response
  },
  (e) => {
    // 요청 오류가 있는 경우
    const errMsg = `[${e.status}] ${e.code}, [${e.method}] ${e.url} => ${e.data}`
    console.warn(`[RSPS-ITCT] err ${errMsg}`)

    // const mthd = e.config.method
    // const url = e.config.url
    // const data = e.config.data
    // const code = e.code
    // const status = e.status
    // const msg = e.message // "Request failed with status code 400"


    if (e.response && e.response.status) {
      if (e.response.status === 600) {
        user_stor().clerUserDataAndGoMain(undefined, true)
        return
      }
    }
    return Promise.reject(e)
  })

function axis_cstm(): AxiosInstance {
  const jwt = user_stor().getJwtStr
  const tp = localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
  if (jwt && jwt.length > 9) {
    laxios.defaults.headers['Authorization'] = `Bearer ${jwt}`
  }
  if (tp) {
    laxios.defaults.headers[BrwsStrgKey.FNGRPRNT_PLUS] = tp
  }
  laxios.defaults.headers[BrwsStrgKey.IS_FROM_VUE] = 'true' // ? 덜 중요
  return laxios
}

export default axis_cstm
