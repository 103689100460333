function preventPageMove(e) {
	e.preventDefault()
	e.returnValue = ''
}

function applyFitScreenY() {
	document.getElementsByTagName('html')[0].classList.add('fit-content')
	// document.body.classList.add('fit-content')
	return false
}

function releaseFitScreenY() {
	document.getElementsByTagName('html')[0].classList.remove('fit-content')
	// document.body.classList.remove('fit-content')
	return false
}

function preventBgScroll(){
	// https://usefulangle.com/post/278/html-disable-pull-to-refresh-with-css
	// https://stackoverflow.com/questions/47918560/prevent-background-scrolling-when-overlay-appears
	document.getElementsByTagName('html')[0].classList.add('oy-lock')
	return false
}

function releaseBgScroll(){
	// https://usefulangle.com/post/278/html-disable-pull-to-refresh-with-css
	// https://stackoverflow.com/questions/47918560/prevent-background-scrolling-when-overlay-appears
	document.getElementsByTagName('html')[0].classList.remove('oy-lock')
	return false
}

function preventScroll(){
	// https://usefulangle.com/post/278/html-disable-pull-to-refresh-with-css
	document.body.classList.add('oy-hidden')
	document.body.addEventListener('touchmove', preventPropa, { passive: false })
	// document.body.addEventListener('wheel', preventPropa, { passive: false })
	return false
}

function releaseScroll(){
	// https://usefulangle.com/post/278/html-disable-pull-to-refresh-with-css
	document.body.classList.remove('oy-hidden')
	document.body.removeEventListener('touchmove', preventPropa, { passive: false })
	// document.body.removeEventListener('wheel', preventPropa, { passive: false })
	return false
}

function preventPropa(e){
	e.preventDefault()
	e.stopPropagation()
	return false
}

function releaseBackpress(callback) {
	document.removeEventListener("backbutton", callback)
}

export function blockRefresh() {
	document.querySelector(".modal-content").classList.add('prevent-refresh')
}

export function unblockRefresh() {
	document.querySelector(".modal-content").classList.remove('prevent-refresh')
}

function fnLockWheel(e) {
  e.preventDefault()
  e.stopPropagation()
  return false
}

function preventPinchZoom() {
  document.addEventListener("touchmove", __blockPinchZoom, { passive: false })
}

function releasePinchZoom() {
  document.removeEventListener("touchmove", __blockPinchZoom, { passive: false })
}

function __blockPinchZoom(e) {
  if (e.touches.length > 1) {
    e.preventDefault() // preventDefault를 사용하려면 passive를 false로 해줘야합니다.
  }
}

function preventTouchMove() {
  document.addEventListener("touchmove", __blockTouchMove, { passive: false })
}

function releaseTouchMove() {
  document.removeEventListener("touchmove", __blockTouchMove, { passive: false })
}

function __blockTouchMove(e) {
  if (e.touches.length===1) {
    e.preventDefault() // preventDefault를 사용하려면 passive를 false로 해줘야합니다.
  }
}

function setWheelLock() {
  document.addEventListener('scroll', fnLockWheel)
}

function releaseWheelLock() {
  document.removeEventListener('scroll',fnLockWheel)
}

function listenBackpress(callback) {
  document.addEventListener("backbutton", callback, false)
}

function getAndSetParm(queryString) {
	let ret = null
	if (queryString) {
		ret = {}
		const searchParams = new URLSearchParams(queryString)
		for (const [key, value] of searchParams.entries()) {
			if (value===null || value==='' || value === []) {
				continue
			} else if (key==='kywd') {
				ret[key] = decodeURIComponent(value).split(' ')
			} else {
				const list = value.split(' ')
				if (list.length > 1) {
					ret[key] = list
				} else {
					ret[key] = value
				}
			}
		}
		if (ret['ordrBy'] ==='AORD0000') {
			delete ret['ordrBy']
		}
	}
	return ret
}

function getFullUrl() {
  return location.href
}

function getParm(key) {
  let queryString = window.location.search
  let urlParams = new URLSearchParams(queryString)
  if( urlParams.has(key)) {
    return urlParams.get(key)
  } else {
    return null
  }
}

function getParmObjt() {
  if (window.location.search) {
    let ret = {}
    const searchParams = new URLSearchParams(window.location.search)
    for (const [key, value] of searchParams.entries()) {
      console.log(`${key}, ${value}`)
      if (key==='kywd') {
        let kywdList = []
        const list = value.split(' ')
        list.forEach((it) => {
          kywdList.push(it)
        })
        ret[key] = kywdList
      } else {
        const list = value.split(' ')
        if (list.length > 1) {
          ret[key] = list
        } else {
          ret[key] = value
        }
      }
    }
    return ret
  } else {
    return null
  }
}

function getAndSetParmObjt(ret) {
  if (window.location.search) {
    const searchParams = new URLSearchParams(window.location.search)
    for (const [key, value] of searchParams.entries()) {
      console.log(`params ${key}, ${value}`)
      if (value===null || value==='' || value === []) {
        continue
      } else if (key==='kywd') {
        let kywdList = []
        const list = value.split(' ')
        list.forEach((it) => {
          kywdList.push(it)
        })
        ret[key] = kywdList
      } else {
        const list = value.split(' ')
        if (list.length > 1) {
          ret[key] = list
        } else {
          ret[key] = value
        }
      }
    }
    console.log(`final params ${ret}`)
  }
}

export { getFullUrl, applyFitScreenY, releaseFitScreenY
  , preventPageMove, setWheelLock, releaseWheelLock, releaseBackpress
  , preventBgScroll, releaseBgScroll, preventScroll, releaseScroll
  , preventPropa, getParm, listenBackpress }
