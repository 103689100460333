<template>
  <div class='carousel row gx-0 gy-2 mx-0'>

    <div v-for='(item, idx) in model.list' :key='idx'
         class="img-wrap">
      <div v-wave :style="'background-image: url(' + item.pblcThumUrl + ')'"
           @click="artClicked(item.grupId, item.id)" role="button"
      >
        <div v-if="item.titl" class='title ellipsis'>
          {{ item.titl }}
        </div>
      </div>
    </div>

    <spnr-no-block v-if="model.loading" theme="white absolute" />
    <no-list-div v-if="!model.loading && (!model.list || model.list.length < 1)"
        :class="prop.theme" />

  </div>
</template>

<script setup>
import { onMounted, reactive, watch } from 'vue'
import NoListDiv from '@/view/pgrsAlrt/NoListDiv.vue'
import SpnrNoBlock from '@/view/pgrsAlrt/SpnrNoBlock.vue'

const emit = defineEmits(['artClicked'])
const prop = defineProps(['list', 'theme'])

const model = reactive({
  loading: true,
  list: []
})

onMounted(() => {
  model.list = prop.list
})

watch(
  () => prop.list,
  (param) => {
    model.list = param
    model.loading = false
  }
)

function artClicked(grupId, itemId) {
  emit('artClicked', grupId, itemId)
}


</script>

<style scoped>
.carousel {
  width: 100%;
  min-height: 199px;
  overflow: hidden;
  background-image: url('@/assets/img/bg/bg-leather-brown-square.avif');
  background-size: cover;
  border: 0;
  padding-bottom: 0.5rem;
}

.img-wrap {
  width: 88px;
  height: 88px;
  display: inline-flex;
  margin-left: 0.5rem;
}

.img-wrap > * {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(255, 255, 255, 0.8);
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.title {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 1rem;
  line-height: 0.8rem;
  text-align: start;
  font-weight: 300;
  font-size: 0.8rem;
  padding: 0.1rem 0 0 0.1rem;
  color: white;
  left: 0;
  width: 100%;
  max-width: 100%;
}

@media (max-width: 991px) {
  .carousel {
    padding-bottom: 103px;
  }
}
</style>
