import axis_cstm from '@/util/axis_cstm'
import { API_ROOT, BrwsStrgKey, LOCALHOST_V4, ResCd } from '@/util/comn_cnst'
import { user_stor } from '@/stor/user_stor'
import MobileDetect from 'mobile-detect'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { hexToBase62, ipV4To64, ipV6To64 } from '@/util/comn_util'

String.prototype.format = function () {
  var formatted = this
  for (var arg in arguments) {
    formatted = formatted.replace('{' + arg + '}', arguments[arg])
  }
  return formatted
}

// https://dev.fingerprint.com/docs/quick-start-guide
const fpPromise = FingerprintJS.load()

async function getFingerprintAsync() {
  return fpPromise.then((fp) => fp.get()).then((res) => res.visitorId)
}

/**
 * Refactored & confirmed
 * @returns {Promise<*>}
 */
function genFingerprint() {
  // 1. Gen fingerprint + ip mix
  let tp = localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)

  //  CAB / 24 / DxN / V / A / A / Bov / Bor
  // const ip1 = '2001:0db8:3c4d:0015:0000:0000:1a2f:1a2b'
  // const ip2 = '2001:db8:3c4d:15::1a2f:1a2b'
  // console.log('hex4To64-1', ipV6To64(ip1))
  // console.log('hex4To64-2', ipV6To64(ip2))

  if (tp && tp.length > 9 && tp.indexOf("$")>0) {
    return tp
  } else {

    let clntIp = ''

    fpPromise.then((fp) => fp.get())
      .then((res) => {
        const encdFngrPrnt = hexToBase62(res.visitorId)
        // console.log('[TRAC] Hex to 62', encdFngrPrnt)
        axis_cstm().get('/cdn-cgi/trace').then((res) => {
          if (res.data) {
            let lines = res.data.split('\n')
            let keyValue
            lines.forEach(function (line) {
              keyValue = line.split('=')
              if (keyValue[0]==='ip') {
                let isV6 = keyValue[1].indexOf(':')>-1
                if (isV6) {
                  clntIp = ipV6To64(keyValue[1])
                } else {
                  clntIp = ipV4To64(keyValue[1])
                }
              }
            })
            if (!clntIp) {
              clntIp = ipV4To64(LOCALHOST_V4)
            }
          }
          // 6Sgx1JcS04cioKTCdKiGv4.B_AAB
          tp = encdFngrPrnt + '$' + clntIp
          localStorage.removeItem(BrwsStrgKey.FNGRPRNT_PLUS)
          localStorage.setItem(BrwsStrgKey.FNGRPRNT_PLUS, tp)
        }).catch((e) => {
          if (!clntIp) {
            clntIp = ipV4To64(LOCALHOST_V4)
          }
          tp = encdFngrPrnt + '$' + clntIp
          localStorage.removeItem(BrwsStrgKey.FNGRPRNT_PLUS)
          localStorage.setItem(BrwsStrgKey.FNGRPRNT_PLUS, tp)
          const httpStatus = e.response.status
          console.warn('[TP] e', JSON.stringify(e))
        })
      })
  }
}

function getCartCnt() {
  if (user_stor().isSignedIn) {
    axis_cstm()
      .get(API_ROOT + 'prvt/cart/cont')
      .then((res) => {
        const data = res.data
        if (data.code === ResCd.SUCCESS) {
          user_stor().setCartCnt(data.rslt)
        }
      })
      .catch((err) => {
        console.log(`${err.name} ${err.code} ${err.message}`)
      })
      .finally(() => {})
  }
}

function getNotiList() {
  if (user_stor().isSignedIn) {
    const noti = sessionStorage.getItem(BrwsStrgKey.NOTI_DATA)
    if (noti && noti.length > 9) {
      user_stor().setAncmInfo(JSON.parse(noti))
    } else {
      axis_cstm()
        .get(API_ROOT + 'prvt/my/noti')
        .then((res) => {
          const data = res.data
          if (data.code === ResCd.SUCCESS && data.rslt) {
            user_stor.set
          }
        })
        .catch((err) => {
          console.log(`${err.name} ${err.code} ${err.message}`)
        })
        .finally(() => {})
    }
  }
}

function isMobile() {
  const md = new MobileDetect(window.navigator.userAgent)
  const device = md.mobile()
  const ret = (device !== null && device.length > 0)
  // console.log('isMobile', ret)
  return ret
}

function getAbstPos(el) {
  if (!el) {
    return
  }
  var rect = el.getBoundingClientRect()
  return { left: rect.left, top: rect.top }
}

export {
  getFingerprintAsync,
  genFingerprint,
  getCartCnt,
  getNotiList,
  isMobile,
  getAbstPos,
}
