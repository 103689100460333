<template>
  <!-- root: zoom-wrap -->
  <div id="zoom-wrap" class="zoom-wrap d-flex">

    <!-- zoomer -->
    <section id="main-splide" class="splide main-splide flex-grow-1"
             aria-label="AI Generated Images Slide">
      <div class="splide__track">
        <ul class="splide__list" role="presentation">
          <template v-if="model.imgList && model.imgList.length>0">
            <li class="splide__slide zoomist-container"
                role="group" aria-roledescription="slide"
                :id="`main-splide-slide${((idx+1)<10)?('0'+(idx+1)):(''+(idx+1))}`"
                v-for="(item, idx) in model.imgList" :key="idx">
              <div class="splide__slide__container zoomist-wrapper">
                <div class="zoomist-image">
                  <img :src="item.pblcWtmkUrl" :alt="'&quot;' + item.titl + '&quot;'">
                </div>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <div class="custom-zoomist-slider" style="--value: 0;"></div>
      <div id="non-block-loader" class="loading-inner position-absolute white d-none">
        <img src="/aset/img/icon/icon-loading-white.webp" />
      </div>
    </section>

    <section id="thum-splide" class="splide">
      <div class="splide__track">
        <ul id="thumbnails" class="splide__list">
          <template v-if="model.imgList && model.imgList.length>0">
            <li class="splide__slide spldThum" v-for="(item, idx) in model.imgList" :key="idx"
                :id="`thum-splide-slide${((idx+1)<10)?('0'+(idx+1)):(''+(idx+1))}`">
              <img :src="item.pblcThumUrl" :alt="'&quot;' + item.titl + '&quot;'">
            </li>
          </template>
        </ul>
      </div>
    </section>
    <!-- zoomer -->

    <div v-if="model.btnShow === true" class="pos-abs-top-right mt-2 me-2 btn-func-area">
      <!-- 삭제 버튼 -->
      <a class="btn btn-sm btn-sqre btn-outline-white shadow-md"
        @click="emit('onRemvRsltImg', model.slctIndx)">
        <i class="fa-regular fa-trash-xmark"></i>
      </a>
      <!-- 삭제 버튼 -->
      <!-- 추가 버튼 -->
      <a class="btn btn-sm btn-sqre btn-outline-white shadow-md ms-2"
        @click="emit('onRsltImgAdd', model.slctIndx)">
        <i class="fa-solid fa-plus"></i>
      </a>
      <!-- 추가 버튼 -->
    </div>

    <!-- 최소화 버튼 -->
    <a role="button" @click="emit('setFullScrn', false)"
      class="btn btn-lg pos-abs-bottom-left btn-min btn-white shadow-md">
      <span>
        <i class="fa-solid fa-arrows-minimize me-2"></i>
      </span>
      <span>Return</span>
    </a>
    <!-- 최소화 버튼 -->

    <template v-if="model.grup && model.slctIndx > -1">
      <!-- 좌상단 타이틀 영역 -->
      <div class="media-title btn-abs-pos-rb text-shadow-sm d-flex justify-content-between">
        <div class="lb-logo-gen8">
          <img src="/aset/img/icon/icon-generated-red.svg" />
        </div>
        <!-- 이미지 해상도 표시 -->
        <div class="img-rsol-info">
          <i class="fa-regular fa-images me-1 svg-shadow-sm"></i>
          <span class="text-primary fw-bold">{{ model.slctIndx + 1 }}</span>
          <span>/</span>
          <span class="me-1">
            {{ model.imgList.length }}
          </span>
          <span v-if="model.imgList.length > 0 && model.slctIndx < model.imgList.length">
            ({{ model.imgList[model.slctIndx].orgnWdth }}x{{ model.imgList[model.slctIndx].orgnHegt }}px, {{ pxNum2Str(model.imgList[model.slctIndx].orgnWdth * model.imgList[model.slctIndx].orgnHegt) }})
          </span>
        </div>
        <!-- 이미지 해상도 표시 -->
      </div>
      <!-- 좌상단 타이틀 영역 -->

      <!-- 우하단 디스크립션 -->
      <div class="pc-bttm-area w-100 text-white pos-abs-bottom-left svg-shadow-sm d-flex justify-content-between px-2">

        <h1 class="text-white flex-grow-1">{{ model.grup.titl }}</h1>

        <div></div>

      </div>
      <!-- 우하단 디스크립션 -->
    </template>
  </div>
  <!-- root: zoom-wrap -->
</template>

<script setup>
// splideJs. ref. https://splidejs.com/guides/themes/
import "@/assets/css/themes/splide-sea-green.min.css"
import "@/assets/css/zoomist.css"
import "../../../public/aset/css/img-detl.css"
import { preventPinchZoom } from '@/assets/js/img-detl'
import { onMounted, reactive, watch } from 'vue'
import { pxNum2Str } from '@/util/comn_util'

const prop = defineProps(['imgList', 'slctIndx', 'btnShow', 'grup', 'step', 'rlodFin', 'noKey'])
const emit = defineEmits(['currItemId', 'onRemvRsltImg', 'onRsltImgAdd', 'setFullScrn', 'onImgLoad'])
const WIDTH_LG = '100%'
const HEIGHT_SM = '80px'
const horzOptnSm = {
  slideFocus: false,
  autoWidth: true,
  fixedHeight: HEIGHT_SM,
  perPage: 6,
  perMove: 1,
  focus: 0,
  keyboard: 'global',
  rewind: false,
  gap: '1px',
  updateOnMove: true,
  pagination: true,
  paginationDirection: 'ltr',
  direction: 'ltr',
  cover: false,
  isNavigation: true
}
// https://splidejs.com/guides/options/#keyboard
const horzOptnMd = {
  slideFocus: false,
  autoWidth: true,
  fixedHeight: HEIGHT_SM,
  perPage: 9,
  perMove: 1,
  focus: 0,
  keyboard: 'global',
  rewind: false,
  gap: '1px',
  updateOnMove: true,
  pagination: true,
  paginationDirection: 'ltr',
  direction: 'ltr',
  cover: false,
  isNavigation: true
}
const horzOptnLg = {
  slideFocus: false,
  autoWidth: true,
  fixedHeight: HEIGHT_SM,
  perPage: 12,
  perMove: 1,
  focus: 0,
  keyboard: 'global',
  rewind: false,
  gap: '1px',
  updateOnMove: true,
  pagination: true,
  paginationDirection: 'ltr',
  direction: 'ltr',
  cover: false,
  isNavigation: true
}
const spldVertOptn = {
  slideFocus: false,
  fixedWidth: WIDTH_LG,
  autoHeight: true,
  heightRatio: 12,
  perPage: 12,
  perMove: 1,
  keyboard: 'global',
  rewind: false,
  gap: '1px',
  updateOnMove: true,
  pagination: true,
  paginationDirection: 'ttb',
  direction: 'ttb',
  cover: false,
  isNavigation: true
}
let spldHorzOptn = {
  slideFocus: true,
  autoWidth: true,
  fixedHeight: '100%',
  breakpoints: {
    640: {
      fixedHeight: '100%'
    }
  },
  perPage: 12,
  perMove: 1,
  arrows: false,
  keyboard: 'global',
  rewind: false,
  updateOnMove: true,
  pagination: true,
  paginationDirection: 'ltr',
  direction: 'ltr',
  cover: false,
  isNavigation: true,
  paginationKeyboard: true
}
const model = reactive({
  init: true,
  btnShow: false,
  imgList: [],
  grup: null,
  imgSrc: null,
  imgAlt: null,
  step: null,
  maxWidth: '',
  zoomed: false,
  slctIndx: -1,
  zoomistMap: {},
  options: spldHorzOptn,
  currSlid: null
})
let splideMain, splideThum, thumbnails

watch(
  () => model.imgList,
  (param) => {
    // console.log('new img list', newParam)
    model.imgList = param
    console.log('watch.imgList', model.imgList.length)
    setTimeout(function() {
      initZoomSplide()
    }, 200)
  }
)

watch(
  () => prop.grup,
  (param) => {
    // console.log('new img list', newParam)
    model.grup = param
  }
)

watch(
  () => prop.rlodFin,
  (newParam) => {
    console.log('prop.rlodFin', newParam, prop.rlodFin)
    if (newParam && newParam > -1) {
      model.slctIndx = newParam
    }
  }
)

watch(
  () => prop.slctIndx,
  (newParam) => {
    console.log('[NEW-IDX]', newParam)
    model.slctIndx = newParam
  }
)

onMounted(() => {
  // horzOptnSm, horzOptnMd, horzOptnLg, vertOptn
  if (location.href.indexOf('/view/submission') > -1) {
  }
  window.onresize = onResize
  if (prop.btnShow && (prop.btnShow === 'true' || prop.btnShow === true)) {
    model.btnShow = true
  }
  if (prop.step && prop.step === 'rgst') {
    model.step = prop.step
  }
  if (prop.grup) {
    model.grup = prop.grup
  }
  if (prop.imgList) {
    model.imgList = prop.imgList
  }

  // console.log('onMounted.imgList', JSON.stringify(model.imgList))
  /*
  const params = new URL(location.href).searchParams
  const itemId = (params && params.get("d")) ? params.get("d") : null
  if (itemId!=null) {
    let idx = 0
    model.imgList.forEach((item) => {
      //console.log(`param ${itemId} vs ${item.id}`)
      if (item.id === itemId) {
        model.slctIndx = idx
      }
      idx++
    })
  } else {
    if (prop.slctIndx && prop.slctIndx > -1) {
      model.slctIndx = prop.slctIndx
    }
  }
  */
  onResize()
})

function initZoomSplide() {

  console.log('splide init')

  if (!model.imgList || model.imgList.length < 1) {
    return
  }
  if (splideMain) {
    splideMain.destroy()
  }
  if (splideThum) {
    splideThum.destroy()
  }
  splideMain = new Splide('#main-splide', {
    type: 'slide',
    rewind: false,
    arrows: true,
    pagination: false
  })
  splideThum = new Splide('#thum-splide', model.options)
  splideMain.sync(splideThum)
  splideMain.mount()
  splideThum.mount()

  splideMain.on('move', function() {
    console.log('[MOVE]')
    if (model.currSlid) {
      model.currSlid.classList.remove('is-active')
    }
    // Splide#index returns the latest slide index:
    let thumbnail = thumbnails[splideMain.index]
    if (thumbnail) {
      thumbnail.classList.add('is-active')
      model.currSlid = thumbnail
    }
  })

  splideMain.on('ready', function() {
    console.log('[READY]')
    // Collects LI elements:
    // let thumbnails = document.getElementsByClassName('spldThum')
    splideThum.go(0)
  })

  splideMain.on('active', function(slide) {
    console.log('[ACTIVE] ', slide.index)
    model.slctIndx = slide.index
    addNewZoom(slide.index)
  })

  splideMain.on('inactive', function(slide) {
    console.log('[INACTIVE] ', slide.index)
    removePrevZoom(slide.index)
  })

  thumbnails = document.querySelectorAll('.spldThum')
  for (let i = 0; i < thumbnails.length; i++) {
    initThumbnail(thumbnails[i], i)
  }

  // The function to initialize each thumbnail.
  function initThumbnail(thumbnail, index) {
    thumbnail.addEventListener('click', function() {
      // move the carousel
      splideMain.go(index)
    })
  }

  onSplideMove(0)
  // splideMain.go(0)
  addNewZoom(0)
  preventPinchZoom()
  console.log('splide init fin')

}

function onSplideMove(indx) {
  if (model.slctIndx === indx) {
    return
  }
  model.slctIndx = indx
}

function addNewZoom(idx) {
  const inxStr = (idx + 1) < 10 ? `0${(idx + 1)}` : `${(idx + 1)}`
  model.zoomistMap[`${idx}`] = new Zoomist(`#main-splide-slide${inxStr}`, {
    maxScale: 4,
    slider: {
      el: '.custom-zoomist-slider',
      direction: 'vertical'
    },
    zoomer: false,
    bounds: true,
    draggable: true
  })
}

function removePrevZoom(idx) {
  if (model.zoomistMap[`${idx}`]) {
    try {
      model.zoomistMap[`${idx}`].reset()
      model.zoomistMap[`${idx}`].destroy(true)
      model.zoomistMap[`${idx}`] = null
    } catch (e) {
      console.warn('[Zoomist] some err when removing zoomist')
    }
  }
}

// https://splidejs.com/tutorials/thumbnail-carousel/
function onResize() {
  const pageWidth = document.body.offsetWidth
  //console.log('pageWidth', pageWidth)
  if (pageWidth > 1699) {
    // splideThum.options = spldVertOptn
  } else {
    // splideThum.options = spldHorzOptn
  }
}

function getThum600Url() {
  return model.imgList[model.slctIndx].pblcThumUrl
}

defineExpose({ getThum600Url })
</script>

<style scoped>

.zoom-wrap {
  height: 100%;
  flex-direction: column;
}

.media-title {
  width: 100%;
  height: fit-content;
  top: 0;
  left: 0;
  line-height: 26px;
  font-size: 1.1rem;
}

h1 {
  font-weight: normal;
  color: white;
  line-height: 1;
}

.img-rsol-info .text-primary {
  font-size: 14px;
  margin-right: 2px;
}
.img-rsol-info .text-primary + span {
  margin-right: 2px;
}

.btn-lg > svg {
  height: 26px;
}

.btn-outline-white {
  color: white;
  border-color: white;
  background-color: rgba(0, 0, 0, 0.4);
}

.btn-func-area {
  z-index: 1;
}

.innr-titl > div {
  height: 16px;
  line-height: 1;
}

.splide {
  padding: 0;
  visibility: visible;
}

#main-splide .splide__slide {
  width: 100%;
}

#main-splide .zoomist-image {
  height: 100%;
  overflow: visible;
}

.splide__track, .splide__list, .splide__slide {
  height: 100%;
}

.splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-rsol-info {
  display: flex;
}

.img-rsol-info > * {
  font-weight: 300;
  line-height: 1;
}

@media (max-width: 991px) {

  h1 {
    font-size: 1rem !important;
  }

  .media-title {
    padding: 0.5rem;
  }

  .lb-logo-gen8 {
    width: 130px;
  }

}

@media (min-width: 992px) {

  h1 {
    font-size: 1.5rem !important;
  }

  .lb-logo-gen8 {
    width: 200px;
  }

  .media-title {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

}

@media (max-width: 1439px) {

  .media-title {
    width: 100%;
  }

  .mobl-imgs-rsol img {
    height: 26px;
  }

}

@media (min-width: 1440px) {

  .img-rsol-info {
    height: 16px;
    line-height: 16px;
  }
}
</style>
