import { createI18n } from 'vue-i18n'
import en_US from '@/lcal/en.json'
import ko_KR from '@/lcal/ko.json'

const i18n = createI18n({
  locale: 'en',
  globalInjection: true,
  fallbackLocale: 'en',
  legacy: false,
  messages: {
    en: en_US,
    ko: ko_KR
  }
})

export { i18n }