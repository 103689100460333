<template>
	<div class="modal" tabindex="-1">
		<div class="loading-wrapper">
			<div class="loading-inner" :class="mdal.getLodrThem?mdal.getLodrThem:''">
				<img v-if="!mdal.getLodrThem || mdal.getLodrThem==='black'" :src="blackLoader" />
				<img v-if="mdal.getLodrThem && mdal.getLodrThem==='white'" :src="whiteLoader" />
				<div class="lodn-text text-center" v-html="mdal.getLodrMesg"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
import blackLoader from '@/assets/img/icon/ic-loading-b.webp'
import whiteLoader from '@/assets/img/icon/ic-loading-w.webp'
import { mdal_stor } from '@/stor/mdal_stor'
// import { onMounted, reactive, watch } from 'vue'

const mdal = mdal_stor()
// const prop = defineProps(['theme', 'hideText'])
// const model = reactive({
// 	mesg: 'Loading'
// })
//
// watch(() => mdal.getLodrMesg,
// 	(param) => {
//   console.log('[W]LodrMsg', param)
//   model.mesg = param
// })
//
// onMounted(() => {
// 	if (mdal.getLodrMesg) {
//     console.log('[M]LodrMsg', param)
// 		model.mesg = mdal.getLodrMesg
// 	}
// })
</script>
